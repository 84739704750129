import React, { createContext, useEffect, useState } from "react";

export const ViewportContext = createContext({ width: window.innerWidth });

interface ViewportProviderProps {
    children: JSX.Element;
}

export const ViewportProvider = ({ children }: ViewportProviderProps) => {
    const [width, setWidth] = useState(window.innerWidth);

    const handleWindowResize = () => {
        setWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    return <ViewportContext.Provider value={{ width }}>{children}</ViewportContext.Provider>;
};
