import React, { useMemo, useCallback, useState, useContext } from "react";
import { useIntl } from "react-intl";
import IndustryChart from "../IndustryChart/IndustryChart";
import TPNAChart from "../TPNAChart/TPNAChart";
import Prtr from "../PRTR";
import { ChartContext } from "../../context/ChartContext";

export type LayoutType = "InPage" | "GridIcons";

interface ChartSectionProps {
    type: LayoutType;
}

export interface CountResponse {
    data: { Count: number };
}

function InPageLayout() {
    const { formatMessage, locale } = useIntl();

    const mediaTypeOptions = useMemo(
        () => [
            {
                text: formatMessage({ id: "releasesAir" }),
                value: "releasesAir",
                mediaTypeValue: "2",
                sortProperty: "2"
            },
            {
                text: formatMessage({ id: "releasesWater" }),
                value: "releasesWater",
                mediaTypeValue: "41",
                sortProperty: "41"
            },
            {
                text: formatMessage({ id: "undergroundInjection" }),
                value: "undergroundInjection",
                mediaTypeValue: "40",
                sortProperty: "40"
            },
            {
                text: formatMessage({ id: "releasesLand" }),
                value: "releasesLand",
                mediaTypeValue: "16",
                sortProperty: "16"
            },
            { text: formatMessage({ id: "disposal" }), value: "disposal", mediaTypeValue: "17", sortProperty: "17" },
            {
                text: formatMessage({ id: "transfersRecycling" }),
                value: "transfersRecycling",
                mediaTypeValue: "26",
                sortProperty: "26"
            },
            {
                text: formatMessage({ id: "furtherManagement" }),
                value: "furtherManagement",
                mediaTypeValue: "25",
                sortProperty: "25"
            }
        ],
        [formatMessage]
    );

    const [mediaType, setMediaType] = useState<{
        text: string;
        value: string;
        mediaTypeValue: string;
        sortProperty: string;
    }>(mediaTypeOptions[0]);

    const tpnaTitleID = "tpnaTitle";
    const industryTitleID = "industryTitle";

    const { values } = useContext(ChartContext);
    const { chartOption } = values;

    const updateMediaType = useCallback(
        (val: string) => {
            const newMediaType = mediaTypeOptions.find((option) => option.value === val) ?? mediaTypeOptions[0];
            setMediaType(newMediaType);
        },
        [mediaTypeOptions]
    );

    const getChart = (chartTitle: string) => {
        switch (chartTitle) {
            case tpnaTitleID:
                return <TPNAChart mediaType={mediaType} />;
            case industryTitleID:
                return <IndustryChart mediaType={mediaType} />;
            default:
                return null;
        }
    };

    return (
        <>
            <div className="inPageLayout">
                <Prtr lang={locale} updateMediaType={updateMediaType} mediaType={mediaType.value} />
                {getChart(chartOption.value)}
            </div>
        </>
    );
}

export default function ChartSection({ type }: ChartSectionProps) {
    switch (type) {
        case "InPage":
            return <InPageLayout />;
        default:
            return null;
    }
}
