import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import NumberFormat from "react-number-format";
import { years } from "../../config";
import { ChartContext } from "../../context/ChartContext";
import { CultureContext } from "../../context/CultureContext";
import { facilityCount, getPollutantReportURL, linkBuilderTPNA, pollutantCount } from "../../utilities/Utility";
import {
    BarChartData,
    ChartData,
    IDHashTable,
    LineChartData,
    ResponseData,
    Results,
    Response,
    IDReducer,
    sumBarChartValues,
    sumLineChartValues
} from "../Chart";
import CECChart from "../ChartComponents/CECChart";
import { CountResponse } from "../ChartComponents/ChartSection";
import { LoadingSpinner } from "../ChartComponents/LoadingSpinner";

interface TPNAChartProps {
    mediaType: { text: string; value: string; mediaTypeValue: string; sortProperty: string };
}

export default function TPNAChart({ mediaType }: TPNAChartProps) {
    const { formatMessage } = useIntl();
    const culture = useContext(CultureContext);
    const { values } = useContext(ChartContext);
    const { year, agencyID, chemicalTypeID } = values;

    const [barChartData, setBarChartData] = useState<BarChartData>([]);
    const [lineChartData, setLineChartData] = useState<LineChartData>([]);
    const [facilityCountData, setFacilityCountData] = useState<number>();
    const [pollutantCountData, setPollutantCountData] = useState<number>();
    const [loading, setLoading] = useState<boolean>(false);
    const [statsLoading, setStatsLoading] = useState<boolean>(false);
    const [pollutantData, setPollutantData] = useState<IDHashTable>({});
    const [chartTotal, setChartTotal] = useState<number>();
    const allOtherPollutantName = formatMessage({ id: "allOtherPollutants" });
    const [totalReleases, setSetTotalReleases] = useState<number>();

    const handlePollutantDotClick = (chemical: string) => {
        if (chemical === allOtherPollutantName) {
            return;
        }
        const chemicalVal = Object.entries(pollutantData).find(([key]) => key === chemical)?.[1];

        if (chemicalVal) {
            const url = getPollutantReportURL(
                culture,
                chemicalVal.toString(),
                mediaType.mediaTypeValue,
                undefined,
                chemicalTypeID.key,
                agencyID.key
            );
            window.open(url);
        }
    };

    const reducer = (results: Results, item: ChartData) => {
        const itemYear = item.year;
        if (!results[itemYear]) {
            results[itemYear] = [item];
        } else {
            results[itemYear].push(item);
        }
        return results;
    };

    const handlePollutantOnClick = (state: { name: string }) => {
        if (!state.name) {
            return;
        }
        const pollutantID = barChartData.find((d) => d.name === state.name)?.id;
        if (pollutantID && state.name !== allOtherPollutantName) {
            const url = getPollutantReportURL(
                culture,
                pollutantID.toString(),
                mediaType.mediaTypeValue,
                year.value,
                chemicalTypeID.key,
                agencyID.key
            );
            window.open(url);
        }
    };

    const groupByYear = (items: ChartData[]) => {
        return items.reduce(reducer, {});
    };

    const addPollutants = (items: ChartData[]) => {
        return items.reduce(IDReducer, {});
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                let yearQuery = undefined;

                if (year.value === "allYears") {
                    yearQuery = years.slice(1, years.length).join(",");
                }
                const url = linkBuilderTPNA(
                    culture,
                    mediaType.mediaTypeValue,
                    mediaType.sortProperty,
                    yearQuery ?? year.text,
                    agencyID.key,
                    chemicalTypeID.key
                );
                const totalReleasesAndTransfersURL = linkBuilderTPNA(
                    culture,
                    "29",
                    "29",
                    yearQuery ?? year.text,
                    agencyID.key,
                    chemicalTypeID.key
                );
                const facilityCountURL = facilityCount(
                    culture,
                    mediaType.sortProperty,
                    yearQuery ?? year.text,
                    agencyID.key,
                    chemicalTypeID.key
                );
                const pollutantCountURL = pollutantCount(
                    culture,
                    mediaType.sortProperty,
                    yearQuery ?? year.text,
                    agencyID.key,
                    chemicalTypeID.key
                );

                setLoading(true);
                const responseData: Response = await axios.get(url);
                setLoading(false);

                const result = (responseData.data as ResponseData[]).map((item) => {
                    return {
                        total: item.Value,
                        name: item.Key.Name,
                        year: item.Year,
                        id: item.Key.ID
                    };
                });

                const filteredAllOthers = result.filter((r) => r.name !== allOtherPollutantName);

                if (year.value !== "allYears") {
                    if (JSON.stringify(filteredAllOthers) !== JSON.stringify(barChartData)) {
                        setChartTotal(sumBarChartValues(result));
                        setBarChartData(filteredAllOthers);
                    }
                } else {
                    setPollutantData(addPollutants(result));
                    const itemsGroupedByYear = groupByYear(result);
                    const numItems = 11;
                    const topItemsByYear = Object.values(itemsGroupedByYear).map((i) =>
                        i.sort((a, b) => b.total - a.total).slice(0, numItems)
                    );

                    const filteredItems = topItemsByYear.map((ti) =>
                        ti.filter((t) => t.name !== allOtherPollutantName)
                    );

                    const allYearsChartData: LineChartData = filteredItems.map((top) =>
                        top.reduce(
                            (prev, item) => {
                                const name = item.name;
                                return { ...prev, year: item.year, [name]: item.total };
                            },
                            { year: 0 }
                        )
                    );

                    const allYearsTotalData: LineChartData = topItemsByYear.map((top) =>
                        top.reduce(
                            (prev, item) => {
                                const name = item.name;
                                return { ...prev, year: item.year, [name]: item.total };
                            },
                            { year: 0 }
                        )
                    );
                    setChartTotal(sumLineChartValues(allYearsTotalData));
                    setLineChartData(allYearsChartData);
                }

                setStatsLoading(true);
                const { data: facilityCountResponse }: CountResponse = await axios.get(facilityCountURL);
                const { data: pollutantCountResponse }: CountResponse = await axios.get(pollutantCountURL);
                const { data: releaseAndTransferResponse }: Response = await axios.get(totalReleasesAndTransfersURL);
                const releaseAndTransferResult = (releaseAndTransferResponse as ResponseData[]).map((item) => {
                    return {
                        total: item.Value,
                        name: item.Key.Name,
                        year: item.Year,
                        id: item.Key.ID
                    };
                });
                setSetTotalReleases(
                    Math.round(releaseAndTransferResult.reduce((prevItem, currItem) => prevItem + currItem.total, 0))
                );

                setStatsLoading(false);
                if (facilityCountResponse) {
                    setFacilityCountData(facilityCountResponse.Count);
                }
                if (pollutantCountResponse) {
                    setPollutantCountData(pollutantCountResponse.Count);
                }
            } catch (error) {
                console.log(error);
            }
        };

        fetchData().catch(() => {});
    }, [year, culture, mediaType, agencyID, chemicalTypeID]); /* eslint-disable-line react-hooks/exhaustive-deps */

    const getFormattedNumber = (val: number | undefined) => {
        if (val === undefined) {
            return "undefined";
        }
        return <NumberFormat value={val} displayType={"text"} thousandSeparator={culture === "fr-CA" ? " " : true} />;
    };

    if (loading) {
        return <LoadingSpinner />;
    }

    return (
        <>
            <CECChart
                chartName="TPNA"
                barChartData={barChartData}
                lineChartData={lineChartData}
                chartFileName={`${formatMessage({ id: "tpnaExportTitle" })}`}
                summaryID={"tpnaSummary1"}
                footerID={"tpnaFooter"}
                facilityCount={statsLoading ? formatMessage({ id: "loading" }) : getFormattedNumber(facilityCountData)}
                pollutantCount={
                    statsLoading ? formatMessage({ id: "loading" }) : getFormattedNumber(pollutantCountData)
                }
                handleOnClick={handlePollutantOnClick}
                handleDotClick={handlePollutantDotClick}
                xAxisBarLabelID={"kilograms"}
                yAxisLineLabelID={"kilograms"}
                totalReleases={statsLoading ? formatMessage({ id: "loading" }) : getFormattedNumber(totalReleases)}
                chartTotal={
                    chartTotal === undefined ? formatMessage({ id: "loading" }) : getFormattedNumber(chartTotal)
                }
                mediaTypeValue={mediaType.value}
            />
        </>
    );
}
