import React from "react";
import NumberFormat from "react-number-format";

export const getArrow = (className: string, upArrow: boolean) => {
    return (
        <div style={{ fontFamily: "Segoe UI Symbol" }} className={className}>
            {upArrow ? "\u2b06" : "\u2b07"}
        </div>
    );
};

export const renderDownArrow = (value: number) => {
    const downArrow1Val = 3000000000;
    const downArrow2Val = 2500000000;
    const downArrow3Val = 100000000;
    const downArrow4Val = 1000000;
    const downArrow5Val = 100000;
    if (value > downArrow1Val) {
        return getArrow("down-arrow-1", false);
    } else if (value > downArrow2Val && value < downArrow1Val) {
        return getArrow("down-arrow-2", false);
    } else if (value > downArrow3Val && value < downArrow2Val) {
        // 500000000 2500000000
        return getArrow("down-arrow-3", false);
    } else if (value > downArrow4Val && value < downArrow3Val) {
        // 100000000 150000000
        return getArrow("down-arrow-4", false);
    } else if (value > downArrow5Val && value < downArrow4Val) {
        // 50000000 100000000
        return getArrow("down-arrow-5", false);
    } else if (value < downArrow5Val) {
        // 50000000
        return getArrow("down-arrow-6", false);
    }
    return undefined;
};

export const renderUpArrow = (value: number) => {
    const downArrow1Val = 3000000000;
    const downArrow2Val = 2500000000;
    const downArrow3Val = 500000000;
    const downArrow4Val = 1000000;
    const downArrow5Val = 100000;
    if (value > downArrow1Val) {
        return getArrow("up-arrow-1", true);
    } else if (value > downArrow2Val && value < downArrow1Val) {
        return getArrow("up-arrow-2", true);
    } else if (value > downArrow3Val && value < downArrow2Val) {
        // 500000000 2500000000
        return getArrow("up-arrow-3", true);
    } else if (value > downArrow4Val && value < downArrow3Val) {
        // 200000000 500000000
        return getArrow("up-arrow-4", true);
    } else if (value > downArrow5Val && value < downArrow4Val) {
        // 100000000 200000000
        return getArrow("up-arrow-5", true);
    } else if (value < downArrow5Val) {
        // 100000000
        return getArrow("up-arrow-6", true);
    }

    return undefined;
};

export const getNumberFormat = (value: number, language: string) => {
    const thousandSeparator = language === "fr-CA" ? " " : true;

    return (
        <NumberFormat
            value={value}
            displayType={"text"}
            thousandSeparator={thousandSeparator}
            renderText={(val) => {
                return (
                    <>
                        <p style={{ fontSize: 18 }}>{val} kg</p>
                    </>
                );
            }}
        />
    );
};
