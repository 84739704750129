import React from "react";
import { Twitter, Facebook, Mail, Linkedin } from "react-social-sharing";

import "./layout.css";

interface ShareProps {
    href: string;
}

const Share = (props: ShareProps) => (
    <div style={{ display: "flex", flexDirection: "row", margin: 10 }}>
        <Facebook style={{ fontSize: 20, borderRadius: 0 }} solid small link={props.href} />
        <Twitter style={{ marginLeft: -14, fontSize: 20, borderRadius: 0 }} solid small link={props.href} />
        <Linkedin style={{ marginLeft: -14, fontSize: 20, borderRadius: 0 }} solid small link={props.href} />
        <Mail style={{ marginLeft: -14, fontSize: 20, borderRadius: 0 }} solid small link={props.href} />
    </div>
);

export default Share;
