import React from "react";
import { Link } from "react-router-dom";

interface I18nLinkProps {
    to: string;
    children: JSX.Element;
}

export default function I18nLink({ to, children, ...rest }: I18nLinkProps) {
    const storedLang = window ? window.localStorage.getItem("language") : "";

    const toWithLang = `/${storedLang || "en"}${to}`;
    return (
        <Link to={toWithLang} {...rest}>
            {children}
        </Link>
    );
}
