import React from "react";
import { Button } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { languages } from "../i18n";
import "./layout.css";

interface LanguageNavProps {
    lang: string;
    path: string;
}

export default function LanguageNav({ lang, path }: LanguageNavProps) {
    const navigate = useNavigate();

    const handleChange = (event: React.MouseEvent<HTMLButtonElement>) => {
        const { value } = event.target as HTMLButtonElement;

        if (value === lang) {
            return;
        }

        localStorage.setItem("language", value);
        navigate(`/${value}/${path}`);
    };

    return (
        <div>
            <Button.Group color="black" size="mini" style={{ marginRight: 10 }}>
                {languages.map(({ value, text }) => {
                    return lang === value ? (
                        <Button
                            key={value}
                            value={value}
                            onClick={handleChange}
                            style={{ backgroundColor: "#77C4D0", fontFamily: "sans-serif" }}
                        >
                            {text}
                        </Button>
                    ) : (
                        <Button key={value} value={value} onClick={handleChange} style={{ fontFamily: "sans-serif" }}>
                            {text}
                        </Button>
                    );
                })}
            </Button.Group>
        </div>
    );
}
