import React, { useCallback } from "react";
import FileSaver from "file-saver";
import { useIntl } from "react-intl";
import LoaderSpinner from "./Loader";

interface ExportButtonProps {
    getPng: () => Promise<string | undefined>;
    chartName: string;
    isLoading: boolean;
}

export function ExportButton({ getPng, chartName, isLoading }: ExportButtonProps) {
    // useCurrentPng usage (isLoading is optional)
    const { formatMessage } = useIntl();

    const handleDownload = useCallback(async () => {
        const png = await getPng();

        // Verify that png is not undefined
        if (png) {
            // Download with FileSaver
            FileSaver.saveAs(png, `${chartName}.png`);
        }
    }, [getPng, chartName]);
    return (
        <button
            disabled={isLoading}
            data-testid="exportbutton"
            onClick={() => {
                handleDownload().catch(() => {});
            }}
            style={{ margin: "16px 0" }}
            data-html2canvas-ignore
        >
            {isLoading ? <LoaderSpinner /> : <code>{formatMessage({ id: "export" })}</code>}
        </button>
    );
}
