import React from "react";
import manganese from "../images/pollutant-manganese.png";
import chromium from "../images/pollutant-chromium.png";
import lead from "../images/pollutant-lead.png";
import hydrochloricAcid from "../images/pollutant-hydrochloric-acid.png";
import barium from "../images/pollutant-barium.png";
import mercury from "../images/pollutant-mercury.png";
import nitricAcid from "../images/pollutant-nitric-acid-and-nitrate-compounds.png";
import phosphorous from "../images/pollutant-phosphorus.png";
import sodiumNitrite from "../images/pollutant-sodium-nitrite.png";
import ImageIcons from "./ImageIcons";

export type IconSize = "wide" | "mid" | "mobile";

interface ImageHoverTextProps {
    imageType: string;
}

export interface ImageProps {
    src: string;
    altName: string;
    messageId: string;
    className?: string;
}

export default function ImageHoverText({ imageType }: ImageHoverTextProps) {
    const electronicImages: ImageProps[] = [
        {
            src: manganese,
            altName: "Manganese",
            messageId: "manganese"
        },
        {
            src: chromium,
            altName: "Chromium",
            messageId: "chromium"
        },
        {
            src: lead,
            altName: "Lead",
            messageId: "lead"
        }
    ];
    const fossilFuelImages: ImageProps[] = [
        {
            src: hydrochloricAcid,
            altName: "Hydrochloric Acid",
            messageId: "hydrochloricAcid"
        },
        {
            src: barium,
            altName: "Barium",
            messageId: "barium",
            className: "info-icon-barium"
        },
        {
            src: mercury,
            altName: "Mercury",
            messageId: "mercury"
        }
    ];
    const foodProdImages: ImageProps[] = [
        {
            src: nitricAcid,
            altName: "Nitric Acid and Nitrate Compounds",
            messageId: "nitricAcid"
        },
        {
            src: phosphorous,
            altName: "Total Phosphorous",
            messageId: "totalPhosphorous"
        },
        {
            src: sodiumNitrite,
            altName: "Sodium Nitrite",
            messageId: "sodiumNitrite"
        }
    ];
    const getHoverTextType = () => {
        switch (imageType) {
            case "electronic":
                return (
                    <>
                        <ImageIcons andCompounds={true} images={electronicImages} />
                    </>
                );
            case "fossil":
                return (
                    <>
                        <ImageIcons andCompounds={true} images={fossilFuelImages} />
                    </>
                );
            case "foodProd":
                return (
                    <>
                        <ImageIcons andCompounds={false} images={foodProdImages} />
                    </>
                );
            default:
                return null;
        }
    };
    return <>{getHoverTextType()}</>;
}
