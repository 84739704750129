import React, { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Accordion, Icon } from "semantic-ui-react";
import { ChartName } from "../Chart";
import { ExcelLink } from "../../utilities/Utility";
import I18nLink from "../../i18n/Link";

interface Stat {
    key: string;
    value: number | string | JSX.Element;
    type: string;
    unit?: string;
}

interface ChartStatsProps {
    stats: Stat[];
    year: string;
    chartName: ChartName;
}

const one = 1;
const two = 2;
const three = 3;

export default function ChartStats({ stats, year, chartName }: ChartStatsProps) {
    const [acc, setAcc] = useState<number>(0);
    const filtersSelected = stats.filter((stat) => stat.type === "filter");
    const results = stats.filter((stat) => stat.type !== "filter");

    const clickAcc = useCallback((val: number) => {
        setAcc((curr) => (val === curr ? 0 : val));
    }, []);

    return (
        <div>
            <div className="chartStatsContainer">
                <h3>
                    <FormattedMessage id="filtersSelected" />
                </h3>
                <ul className="chartStats">
                    <b>
                        <FormattedMessage id="year" />:{" "}
                    </b>{" "}
                    {year}
                    {filtersSelected.map((val, index) => {
                        return (
                            <div key={index}>
                                <b>
                                    <FormattedMessage id={val.key} />
                                </b>
                                : {val.value}
                            </div>
                        );
                    })}
                </ul>
                <h3>
                    <FormattedMessage id="results" />
                </h3>
                <ul>
                    {results.map((val, index) => {
                        return (
                            <div key={index}>
                                <b>
                                    <FormattedMessage id={val.key} />
                                </b>
                                : {val.value} {val.unit}
                            </div>
                        );
                    })}
                </ul>
            </div>
            {chartName === "TPNA" ? (
                <div className="chartStatsConsiderations" data-html2canvas-ignore>
                    <Accordion styled className="tpna1">
                        <Accordion.Title active onClick={() => clickAcc(one)}>
                            <div className="accordionIcon">
                                <Icon name={acc === one ? "caret down" : "caret right"} />
                            </div>
                            <div className="accordionTitle">
                                <FormattedMessage id="impconTpna1Title" />
                            </div>
                        </Accordion.Title>
                        <Accordion.Content active={acc === one}>
                            <p>
                                <FormattedMessage id="impconTpna1part1" />
                            </p>
                            <ul>
                                <li>
                                    <b>
                                        <FormattedMessage id="impconTpna1part1ca" />
                                    </b>{" "}
                                    <FormattedMessage id="impconTpna1part1ca1" />
                                </li>
                                <li>
                                    <b>
                                        <FormattedMessage id="impconTpna1part1mx" />
                                    </b>{" "}
                                    <FormattedMessage id="impconTpna1part1mx1" />
                                </li>
                                <li>
                                    <b>
                                        <FormattedMessage id="impconTpna1part1us" />
                                    </b>{" "}
                                    <FormattedMessage id="impconTpna1part1us1" />
                                </li>
                            </ul>
                            <p>
                                <FormattedMessage id="impconTpna1part2" />
                            </p>
                            <p>
                                <b>
                                    <FormattedMessage id="impconTpna1part3" />{" "}
                                    <a href={ExcelLink}>
                                        <FormattedMessage id="prtrtab1p3link" />
                                    </a>
                                </b>
                            </p>
                        </Accordion.Content>
                    </Accordion>
                    <Accordion styled className="tpna2">
                        <Accordion.Title active onClick={() => clickAcc(two)}>
                            <div className="accordionIcon">
                                <Icon name={acc === two ? "caret down" : "caret right"} />
                            </div>
                            <div className="accordionTitle">
                                <FormattedMessage id="impconTpna2Title" />
                            </div>
                        </Accordion.Title>
                        <Accordion.Content active={acc === two}>
                            <p>
                                <FormattedMessage id="impconTpna2part1" />
                            </p>
                            <ul>
                                <li>
                                    <FormattedMessage id="knownOrSusCarc" />
                                </li>
                                <li>
                                    <FormattedMessage id="devReproToxins" />
                                </li>
                                <li>
                                    <FormattedMessage id="metals" />
                                </li>
                                <li>
                                    <FormattedMessage id="persBioToxic" />
                                </li>
                            </ul>
                            <p>
                                <b>
                                    <i>
                                        <FormattedMessage id="impconTpna2part2" />
                                    </i>
                                </b>
                            </p>
                            <p>
                                <b>
                                    <FormattedMessage
                                        id="prtrtab1p2"
                                        values={{
                                            featuresLink: (
                                                <I18nLink to="/prtr">
                                                    <FormattedMessage id="understandingTS" />
                                                </I18nLink>
                                            )
                                        }}
                                    />
                                </b>
                            </p>
                        </Accordion.Content>
                    </Accordion>
                    <Accordion styled className="tpna3">
                        <Accordion.Title active onClick={() => clickAcc(three)}>
                            <div className="accordionIcon">
                                <Icon name={acc === three ? "caret down" : "caret right"} />
                            </div>
                            <div className="accordionTitle">
                                <FormattedMessage id="impconTpna3Title" />
                            </div>
                        </Accordion.Title>
                        <Accordion.Content active={acc === three}>
                            <p>
                                <FormattedMessage id="impconTpna3part1" />
                            </p>
                            <p>
                                <b>
                                    <FormattedMessage
                                        id="prtrtab1p2"
                                        values={{
                                            featuresLink: (
                                                <I18nLink to="/scope">
                                                    <FormattedMessage id="understandingTS" />
                                                </I18nLink>
                                            )
                                        }}
                                    />
                                </b>
                            </p>
                            <p>
                                <b>
                                    <FormattedMessage id="impconTpna3part2" />
                                </b>{" "}
                                <u>
                                    <FormattedMessage id="impconTpna3part3" />
                                </u>{" "}
                                <FormattedMessage id="impconTpna3part4" />
                            </p>
                            <p>
                                <b>
                                    <FormattedMessage
                                        id="prtrtab1p2"
                                        values={{
                                            featuresLink: (
                                                <I18nLink to="/prtr">
                                                    <FormattedMessage id="understandingTS" />
                                                </I18nLink>
                                            )
                                        }}
                                    />
                                </b>
                            </p>
                        </Accordion.Content>
                    </Accordion>
                </div>
            ) : (
                <div className="chartStatsConsiderations" data-html2canvas-ignore>
                    <Accordion styled className="ind1">
                        <Accordion.Title active onClick={() => clickAcc(one)}>
                            <div className="accordionIcon">
                                <Icon name={acc === one ? "caret down" : "caret right"} />
                            </div>
                            <div className="accordionTitle">
                                <FormattedMessage id="impconNaics1Title" />
                            </div>
                        </Accordion.Title>
                        <Accordion.Content active={acc === one}>
                            <p>
                                <FormattedMessage id="impconNaics1part1" />
                            </p>
                            <p>
                                <b>
                                    <FormattedMessage
                                        id="prtrtab1p2"
                                        values={{
                                            featuresLink: (
                                                <I18nLink to="/prtr">
                                                    <FormattedMessage id="understandingTS" />
                                                </I18nLink>
                                            )
                                        }}
                                    />
                                </b>
                            </p>
                        </Accordion.Content>
                    </Accordion>
                    <Accordion styled className="ind2">
                        <Accordion.Title active onClick={() => clickAcc(two)}>
                            <div className="accordionIcon">
                                <Icon name={acc === two ? "caret down" : "caret right"} />
                            </div>
                            <div className="accordionTitle">
                                <FormattedMessage id="impconNaics2Title" />
                            </div>
                        </Accordion.Title>
                        <Accordion.Content active={acc === two}>
                            <p>
                                <FormattedMessage id="impconNaics2part1" />
                            </p>
                            <ul>
                                <li>
                                    <b>
                                        <FormattedMessage id="impconTpna1part1ca" />
                                    </b>{" "}
                                    <FormattedMessage id="impconNaics2part1ca1" />
                                </li>
                                <li>
                                    <b>
                                        <FormattedMessage id="impconTpna1part1mx" />
                                    </b>{" "}
                                    <FormattedMessage id="impconNaics2part1mx1" />
                                </li>
                                <li>
                                    <b>
                                        <FormattedMessage id="impconTpna1part1us" />
                                    </b>{" "}
                                    <FormattedMessage id="impconNaics2part1us1" />
                                </li>
                            </ul>
                            <p>
                                <FormattedMessage id="impconNaics2part2" />
                            </p>
                            <p>
                                <b>
                                    <FormattedMessage
                                        id="prtrtab1p2"
                                        values={{
                                            featuresLink: (
                                                <I18nLink to="/features">
                                                    <FormattedMessage id="understandingTS" />
                                                </I18nLink>
                                            )
                                        }}
                                    />
                                </b>
                            </p>
                        </Accordion.Content>
                    </Accordion>
                </div>
            )}
        </div>
    );
}
