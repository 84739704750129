import React, { useCallback, useState } from "react";
import ReactTooltip from "react-tooltip";
import { CartesianGrid, Label, Legend, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { useIntl } from "react-intl";
import { LineChartData, LineGraphDotPayload, strokes } from "../Chart";
import CustomizedNumberTick from "./CustomizedNumberTick";
import CustomizedLineDot from "./CustomizedLineDot";
import AllYearsHoverData from "./AllYearsHoverData";
// import CustomLineTooltip from "./CustomLineTooltip";

interface LineChartProps {
    containerSize: { width: number | string; height: number };
    chartSize: { width: number; height: number };
    xAxisLabelProps: { offset: number; dy: number; labelId: string };
    yAxisLabelProps: { labelId: string };
    footerLabelProps: { footerId: string; offset: number; dy: number; width: number };
    chartMargins: { top: number; right: number; left: number; bottom: number };
    data: LineChartData;
    handleDotClick?: (val: string) => void;
    mediaType: string;
    chartName: string;
}

export function LineGraph({
    containerSize,
    chartSize,
    xAxisLabelProps,
    yAxisLabelProps,
    footerLabelProps,
    chartMargins,
    data,
    handleDotClick,
    mediaType,
    chartName
}: LineChartProps) {
    const { formatMessage } = useIntl();
    const keys = data !== undefined && data.length > 0 ? Object.keys(data[0]).filter((k) => k !== "year") : [];
    const disposalKeys = [
        "disposalSubCatLandfill",
        "disposalSubCatUndergroundInjection",
        "disposalSubCatLandApplication",
        "disposalSubCatStabilization",
        "disposalSubCatStorage",
        "disposalSubCatOther"
    ];
    const furtherManagementKeys = ["furtherManagementTreatment", "furtherManagementSewage", "furtherManagementEnergy"];
    const startingDy = 30;
    const offset = 12;

    const [hoverMsg, setHoverMsg] = useState<string | JSX.Element>("");
    const handleHover = useCallback(
        (datakey: string, enabled: boolean, payload?: LineGraphDotPayload, unit?: string) => {
            setHoverMsg(
                enabled ? (
                    <AllYearsHoverData payload={payload} datakey={datakey} unit={unit} chartName={chartName} />
                ) : (
                    ""
                )
            );
        },
        [chartName]
    );

    return (
        <>
            <a data-for="dotHover" data-tip={formatMessage({ id: "loading" })}>
                <ResponsiveContainer width={containerSize.width} height={containerSize.height}>
                    <LineChart width={chartSize.width} height={chartSize.height} data={data} margin={chartMargins}>
                        <XAxis width={200} type="category" dataKey={"year"} angle={90} dy={20}>
                            <Label
                                value={formatMessage({ id: xAxisLabelProps.labelId })}
                                position="bottom"
                                offset={xAxisLabelProps.offset}
                                dy={xAxisLabelProps.dy}
                            />
                            {mediaType === "disposal" ? (
                                <>
                                    <Label
                                        style={{ fontSize: 10 }}
                                        value={formatMessage({ id: "disposalFooter" })}
                                        position="insideBottomLeft"
                                        offset={-25}
                                        overflow={"true"}
                                        dy={startingDy}
                                        dx={-100}
                                        width={footerLabelProps.width}
                                    />
                                    {disposalKeys.map((dk, index) => {
                                        return (
                                            <Label
                                                style={{ fontSize: 10 }}
                                                value={`${formatMessage({ id: dk })}`}
                                                position="insideBottomLeft"
                                                offset={footerLabelProps.offset}
                                                overflow={"true"}
                                                dy={startingDy + offset + index * offset}
                                                dx={-85}
                                                width={footerLabelProps.width}
                                                key={index}
                                            />
                                        );
                                    })}
                                </>
                            ) : null}
                            {mediaType === "furtherManagement" ? (
                                <>
                                    <Label
                                        style={{ fontSize: 10 }}
                                        value={formatMessage({ id: footerLabelProps.footerId })}
                                        position="insideBottomLeft"
                                        offset={footerLabelProps.offset}
                                        overflow={"true"}
                                        dy={startingDy}
                                        dx={-100}
                                        width={footerLabelProps.width}
                                    />
                                    {furtherManagementKeys.map((fk, index) => {
                                        return (
                                            <Label
                                                style={{ fontSize: 10 }}
                                                value={`${formatMessage({ id: fk })}`}
                                                position="insideBottomLeft"
                                                offset={footerLabelProps.offset}
                                                overflow={"true"}
                                                dy={startingDy + offset + index * offset}
                                                dx={-85}
                                                width={footerLabelProps.width}
                                                key={index}
                                            />
                                        );
                                    })}
                                </>
                            ) : null}
                        </XAxis>
                        <YAxis type="number" tick={<CustomizedNumberTick chartType="line" />}>
                            <Label
                                value={formatMessage({ id: yAxisLabelProps.labelId })}
                                position="left"
                                offset={0}
                                dx={-50}
                                dy={-25}
                                angle={-90}
                            />
                        </YAxis>
                        {/* <Tooltip content={<CustomLineTooltip lang={culture} />} /> */}
                        <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                        <Legend verticalAlign="top" layout="horizontal" align="left" wrapperStyle={{ top: "5px" }} />
                        {keys.map((key, index) => {
                            return (
                                <Line
                                    type="monotone"
                                    connectNulls
                                    key={index}
                                    dataKey={key}
                                    stroke={strokes[index]}
                                    dot={
                                        <CustomizedLineDot
                                            color={strokes[index]}
                                            dataKey={key}
                                            labelId={yAxisLabelProps.labelId}
                                            handleDotClick={handleDotClick}
                                            handleHover={handleHover}
                                        />
                                    }
                                />
                            );
                        })}
                    </LineChart>
                </ResponsiveContainer>
            </a>

            {hoverMsg ? (
                <ReactTooltip id="dotHover" type="light" place="top" multiline={true} isCapture={true}>
                    <div style={{ maxWidth: 200 }}>{hoverMsg}</div>
                </ReactTooltip>
            ) : null}
        </>
    );
}
