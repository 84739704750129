import React from "react";
import { FormattedMessage } from "react-intl";
import { ChartName } from "../Chart";
import "./Charts.css";

interface ChartHeaderData {
    title: string;
    className: string;
    hoverTextId: string;
}

interface ChartHeaderProps {
    title: ChartName;
    agencyID: string;
}

function getHeaderData(title: ChartName): ChartHeaderData {
    switch (title) {
        case "TPNA":
            return {
                title: "tpnaTitle",
                className: "tpnaTitle",
                hoverTextId: "hoverText2"
            };
        case "Industry":
            return {
                title: "industryTitle",
                className: "industryTitle",
                hoverTextId: "hoverText1"
            };

        default:
            // Assume TPNA as default
            return {
                title: "tpnaTitle",
                className: "tpnaTitle",
                hoverTextId: "hoverText2"
            };
    }
}

export default function ChartHeader(props: ChartHeaderProps) {
    const { title, agencyID } = props;
    const { title: chartTitle } = getHeaderData(title);
    return (
        <>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between"
                }}
            >
                <h1 className="chartHeaderTitle">
                    <FormattedMessage id={chartTitle} /> <FormattedMessage id="for" />{" "}
                    <FormattedMessage id={agencyID} />
                </h1>
            </div>
        </>
    );
}
