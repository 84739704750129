/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access */
import html2canvas, { Options } from "html2canvas";
import { Component, createRef, useCallback, useRef, useState } from "react";

export type UseCurrentPng = [
    () => Promise<string | undefined>,
    {
        isLoading: boolean;
        ref: React.MutableRefObject<any>;
    }
];

/**
 * @param options - optional html2canvas Options object
 */
export function useCurrentPng(options?: Partial<Options>): UseCurrentPng {
    const ref = useRef<any>();
    const [isLoading, setIsLoading] = useState(false);

    const getPng = useCallback(async () => {
        if (ref !== null && (ref?.current as HTMLElement)) {
            setIsLoading(true);
            const chartContent = ref.current as HTMLElement;

            return html2canvas(chartContent, {
                logging: false,
                ...options
            }).then((canvas) => {
                setIsLoading(false);
                return canvas.toDataURL("image/png", 1.0);
            });
        }
        return undefined;
    }, [options]);

    return [
        getPng,
        {
            ref,
            isLoading
        }
    ];
}

export interface CurrentPngProps {
    chartRef: React.RefObject<any>;
    getPng: (options?: Partial<Options>) => Promise<string | undefined>;
    isLoading: boolean;
}

interface Props {
    children: (props: CurrentPngProps) => React.ReactNode;
}

interface State {
    isLoading: boolean;
}

export class CurrentPng extends Component<Props, State> {
    private chartRef = createRef<any>();

    state: State = {
        isLoading: false
    };

    getPng = async (options?: Partial<Options>) => {
        if (this.chartRef.current?.container) {
            this.setState({ isLoading: true });
            const chartContent = this.chartRef.current.ref.current.firstElementChild as HTMLElement;
            const exportButton = chartContent.querySelector("button");
            if (exportButton) {
                chartContent.removeChild(exportButton);
            }
            return html2canvas(this.chartRef.current.ref.current.firstElementChild as HTMLElement, {
                ...options
            }).then((canvas) => {
                this.setState({ isLoading: false });
                return canvas.toDataURL("image/png", 1.0);
            });
        }
        return undefined;
    };

    render() {
        return this.props.children({
            chartRef: this.chartRef,
            getPng: this.getPng,
            isLoading: this.state.isLoading
        });
    }
}
