import React from "react";
import { useIntl } from "react-intl";
import ToolTip from "./ToolTip";

interface CustomToolTipProps {
    active?: boolean;
    payload?: { payload: { name: string; total: number; releasesTotal?: number }; value: number }[];
    label?: string;
    labelUnit: string;
    chartName: string;
}

export default function CustomTooltip({ active, payload, label, labelUnit, chartName }: CustomToolTipProps) {
    const { formatMessage } = useIntl();
    const allOtherPollutantName = formatMessage({ id: "allOtherPollutants" });
    const allOtherSectorName = formatMessage({ id: "allOtherSectors" });

    const getHoverMsg = () => {
        if (chartName === "Industry") {
            return formatMessage({ id: "clickBarIndustryExplore" });
        }
        return formatMessage({ id: "clickBarPollutantExplore" });
    };
    if (active && payload && payload.length > 0) {
        return (
            <>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        backgroundColor: "white",
                        padding: 5,
                        width: 200
                    }}
                >
                    <ToolTip label={label} labelUnit={labelUnit} val={payload[0].value} />
                    {label === allOtherPollutantName || label === allOtherSectorName ? null : (
                        <div style={{ marginTop: 6 }}>{getHoverMsg()}</div>
                    )}
                </div>
            </>
        );
    }

    return null;
}
