import { useContext } from "react";
import { ViewportContext } from "../../context/ViewportContext";

export const useViewport = () => {
    const { width } = useContext(ViewportContext);
    const breakpoint = 1023;

    // Return the width so we can use it in our components
    return { width: width, breakpoint: breakpoint };
};
