import React from "react";
import { Bar, BarChart, CartesianGrid, Cell, Label, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { useIntl } from "react-intl";
import { BarChartData } from "../Chart";
import CustomizedNumberTick from "./CustomizedNumberTick";
import CustomTooltip from "./CustomToolTip";

interface BarGraphProps {
    containerSize: { width: number | string; height: number };
    chartSize: { width: number; height: number };
    xAxisLabelProps: { offset: number; dy: number; labelId: string };
    footerLabelProps: { footerId: string; offset: number; dy: number; width: number };
    chartMargins: { top: number; right: number; left: number; bottom: number };
    data: BarChartData;
    barCategoryGap: number | string;
    handleOnClick?: (val: { name: string }) => void;
    mediaType: string;
    chartName: string;
}

export const BarGraph = ({
    containerSize,
    chartSize,
    xAxisLabelProps,
    footerLabelProps,
    chartMargins,
    data,
    barCategoryGap,
    handleOnClick,
    mediaType,
    chartName
}: BarGraphProps) => {
    const { formatMessage } = useIntl();
    const allOtherPollutants = formatMessage({ id: "allOtherPollutants" });
    const allOtherSectors = formatMessage({ id: "allOtherSectors" });
    const disposalKeys = [
        "disposalSubCatLandfill",
        "disposalSubCatUndergroundInjection",
        "disposalSubCatLandApplication",
        "disposalSubCatStabilization",
        "disposalSubCatStorage",
        "disposalSubCatOther"
    ];
    const furtherManagementKeys = ["furtherManagementTreatment", "furtherManagementSewage", "furtherManagementEnergy"];
    const startingDy = 30;
    const offset = 12;
    const industryYAxisWidth = 200;
    const tpnaYAxisWidth = 110;
    return (
        <div>
            <ResponsiveContainer width={containerSize.width} height={containerSize.height}>
                <BarChart
                    width={chartSize.width}
                    height={chartSize.height}
                    layout="vertical"
                    barCategoryGap={barCategoryGap}
                    data={data}
                    margin={chartMargins}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis width={200} height={40} type="number" tick={<CustomizedNumberTick chartType="bar" />}>
                        <Label
                            value={formatMessage({ id: xAxisLabelProps.labelId })}
                            position="bottom"
                            offset={xAxisLabelProps.offset}
                            dy={xAxisLabelProps.dy}
                        />

                        {mediaType === "disposal" ? (
                            <>
                                <Label
                                    style={{ fontSize: 10 }}
                                    value={formatMessage({ id: "disposalFooter" })}
                                    position="insideBottomLeft"
                                    offset={0}
                                    overflow={"true"}
                                    dy={startingDy}
                                    dx={-120}
                                    width={footerLabelProps.width}
                                />
                                {disposalKeys.map((dk, index) => {
                                    return (
                                        <Label
                                            style={{ fontSize: 10 }}
                                            value={`${formatMessage({ id: dk })}`}
                                            position="insideBottomLeft"
                                            offset={footerLabelProps.offset}
                                            overflow={"true"}
                                            dy={startingDy + offset + index * offset}
                                            dx={-105}
                                            width={footerLabelProps.width}
                                            key={index}
                                        />
                                    );
                                })}
                            </>
                        ) : null}
                        {mediaType === "furtherManagement" ? (
                            <>
                                <Label
                                    style={{ fontSize: 10, wordWrap: "normal" }}
                                    value={formatMessage({ id: footerLabelProps.footerId })}
                                    position="insideBottomLeft"
                                    offset={footerLabelProps.offset}
                                    overflow={"true"}
                                    dx={-120}
                                    dy={startingDy}
                                    width={footerLabelProps.width}
                                />
                                {furtherManagementKeys.map((fk, index) => {
                                    return (
                                        <Label
                                            style={{ fontSize: 10 }}
                                            value={`${formatMessage({ id: fk })}`}
                                            position="insideBottomLeft"
                                            offset={footerLabelProps.offset}
                                            overflow={"true"}
                                            dy={startingDy + offset + index * offset}
                                            dx={-105}
                                            width={footerLabelProps.width}
                                            key={index}
                                        />
                                    );
                                })}
                            </>
                        ) : null}
                    </XAxis>
                    <YAxis
                        width={chartName === "Industry" ? industryYAxisWidth : tpnaYAxisWidth}
                        type="category"
                        dataKey="name"
                    />
                    <Tooltip content={<CustomTooltip labelUnit={xAxisLabelProps.labelId} chartName={chartName} />} />
                    <Bar
                        barSize={40}
                        dataKey="total"
                        fill="#77C4D0"
                        background={{ fill: "transparent" }}
                        onClick={handleOnClick}
                    >
                        {data.map((entry, index) =>
                            entry.name === allOtherPollutants || entry.name === allOtherSectors ? (
                                <Cell key={`cell-${index}`} />
                            ) : (
                                <Cell style={{ cursor: "pointer" }} key={`cell-${index}`} />
                            )
                        )}
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};
