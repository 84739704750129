import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ReactTooltip from "react-tooltip";
import { Image } from "semantic-ui-react";
import ImageHoverText from "./ImageHoverText";

interface InfoImageProps {
    imageSource: string;
    titleId: string;
    paragraphId: string;
    imageType: string;
    hoverId: string;
}

export default function InfoImage({ imageSource, titleId, paragraphId, imageType, hoverId }: InfoImageProps) {
    const { formatMessage } = useIntl();
    return (
        <>
            <div className={"info-stack"}>
                <a data-for={hoverId} data-tip={formatMessage({ id: "loading" })}>
                    <Image src={imageSource} className={"info-image"} data-html2canvas-ignore />
                    <p className={"info-title"}>
                        <FormattedMessage id={titleId} />
                    </p>
                </a>
                <ReactTooltip id={hoverId} type="light" place="top" multiline={true} isCapture={true}>
                    <ImageHoverText imageType={imageType} />
                </ReactTooltip>

                <p className={"info-paragraph"}>
                    <FormattedMessage id={paragraphId} />
                </p>
            </div>
        </>
    );
}
