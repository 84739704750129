import React, { useContext } from "react";
import NumberFormat from "react-number-format";
import { CultureContext } from "../../context/CultureContext";

interface ToolTipProps {
    label?: string;
    labelUnit: string;
    val: number;
}

export default function ToolTip({ label, labelUnit, val }: ToolTipProps) {
    const culture = useContext(CultureContext);

    const labelValue = "kg";

    return (
        <>
            <p style={{ fontWeight: "bold", marginBottom: 2 }}>{`${label ?? ""}`}:</p>
            <NumberFormat
                value={Math.floor(val)}
                displayType={"text"}
                thousandSeparator={culture === "fr-CA" ? " " : true}
                renderText={(value) => (
                    <p style={{ marginLeft: 5 }}>
                        {value} {labelValue}
                    </p>
                )}
            />
        </>
    );
}
