import React from "react";
import { Container } from "semantic-ui-react";
import CBTLink from "./CBTLink";
import MainDatabaseLink from "./MainDatabaseLink";
import MapLink from "./MapLink";
import "./OrangeIconSet.css";

export default function OrangeIconSet() {
    return (
        <Container>
            <div className="orangeIconSetLinkContainer">
                <MainDatabaseLink />
                <CBTLink />
                <MapLink />
            </div>
        </Container>
    );
}
