import React, { useContext } from "react";
import { useIntl } from "react-intl";
import NumberFormat from "react-number-format";
import { LineGraphDotPayload } from "../Chart";
import { CultureContext } from "../../context/CultureContext";

interface AllYearsHoverDataProps {
    payload?: LineGraphDotPayload;
    unit?: string;
    datakey?: string;
    chartName?: string;
}

export default function AllYearsHoverData({ payload, datakey, unit, chartName }: AllYearsHoverDataProps) {
    const { formatMessage } = useIntl();
    const culture = useContext(CultureContext);

    const getHoverMsg = () => {
        if (chartName === "Industry") {
            return <p>{formatMessage({ id: "clickDotIndustryExplore" })}</p>;
        }
        return <p>{formatMessage({ id: "clickDotPollutantExplore" })}</p>;
    };

    if (!payload || !datakey) {
        return null;
    }

    const allOtherPollutants = formatMessage({ id: "allOtherPollutants" });
    const allOtherSectors = formatMessage({ id: "allOtherSectors" });
    const val = Math.floor(payload[datakey]);
    return (
        <>
            <span>
                <b>{payload.year}</b>
            </span>
            <p>
                <b>{datakey}</b>:{" "}
                <NumberFormat value={val} displayType={"text"} thousandSeparator={culture === "fr-CA" ? " " : true} />{" "}
                {unit}
            </p>
            {datakey === allOtherPollutants || datakey === allOtherSectors ? null : getHoverMsg()}
        </>
    );
}
