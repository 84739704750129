import React from "react";
import Footer from "../components/Footer";
import Layout from "../components/Layout";
import NavBar from "../components/Navbar";
import SEO from "../components/Seo";

interface PageWrapperProps {
    children: JSX.Element;
    lang: string;
    originalPath: string;
    title: string;
    keywords: string[];
}

export default function PageWrapper({ children, lang, originalPath, title, keywords }: PageWrapperProps) {
    return (
        <>
            <NavBar navbarLanguage={lang} originalPath={originalPath}>
                <Layout>
                    <>
                        <SEO title={title} keywords={keywords} lang={lang} />
                        {children}
                        <Footer />
                    </>
                </Layout>
            </NavBar>
        </>
    );
}
