import React from "react";
import browserLang from "browser-lang";
import { Navigate } from "react-router-dom";
import { languages } from "./index";

export default function RedirectPage() {
    const langKeys = languages.map((language) => language.value);
    const storedLang = window ? window.localStorage.getItem("language") : "";

    const detected = storedLang || browserLang({ languages: langKeys, fallback: "en" });

    window.localStorage.setItem("language", detected);

    return <Navigate to={`/${detected}`} />;
}
