import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { years } from "../../config";
import { CultureContext } from "../../context/CultureContext";
import { linkBuilderCAUS, linkBuilderMXUS, linkBuilderUSCA, linkBuilderUSMX } from "../../utilities/Utility";
import CrossborderTransfersDesktop from "./CrossBorderTransfers";

interface Response {
    data: { Items: { Values: { [key: string]: { TotalOffSiteReleasesTransfers: number } } }[] };
}

export default function CrossBorderTransfers() {
    const culture = useContext(CultureContext);
    const [year, setYear] = useState<string>(years[1].toString());
    const [totalForAll, setTotalForAll] = useState(0);
    const [canadaToUS, setCanadaToUS] = useState(0);
    const [mexicoToUS, setMexicoToUS] = useState(0);
    const [USToCanada, setUSToCanada] = useState(0);
    const [USToMexico, setUSToMexico] = useState(0);

    useEffect(() => {
        const getPollutantTransfers = async (
            linkBuilder: (lang: string, yr: string) => string,
            countrySetter: (total: number) => void
        ) => {
            let total = 0;
            await axios
                .get(linkBuilder(culture, year))
                .then((response: Response) => {
                    if (response.data.Items[0] !== undefined) {
                        const transfers = response.data.Items[0].Values[year].TotalOffSiteReleasesTransfers;
                        total = Math.floor(transfers);
                        countrySetter(total);
                        return total;
                    }
                    return 0;
                })
                .catch((error) => {
                    console.log(error);
                });
            return total;
        };

        const getCountryCallSet = async () => {
            const canadaToUSTotal = await getPollutantTransfers(linkBuilderCAUS, setCanadaToUS);
            const mexicoToUSTotal = await getPollutantTransfers(linkBuilderMXUS, setMexicoToUS);
            const USToCanadaTotal = await getPollutantTransfers(linkBuilderUSCA, setUSToCanada);
            const USToMexicoTotal = await getPollutantTransfers(linkBuilderUSMX, setUSToMexico);
            setTotalForAll(canadaToUSTotal + mexicoToUSTotal + USToCanadaTotal + USToMexicoTotal);
        };

        getCountryCallSet().catch(() => {});
    }, [year, culture]);

    const yearDescendingOptions = years
        .filter((y) => y !== "allYears")
        .map((y) => ({ text: y.toString(), value: y.toString() }));

    return (
        <div className="cbtContainer">
            <CrossborderTransfersDesktop
                year={year}
                totalForAll={totalForAll}
                canadaToUS={canadaToUS}
                mexicoToUS={mexicoToUS}
                USToCanada={USToCanada}
                USToMexico={USToMexico}
                yearDescendingOptions={yearDescendingOptions}
                setCbtYear={setYear}
            />
        </div>
    );
}
