export const globalUrl = "";
// CrossBorder Transfer Link Builders
export const linkBuilderMXUS = (lang: string, year: string) => {
    const url = `${globalUrl}/api/GetReport?AgencyIDs=2&Culture=${lang}&IsDrilldown=true&Measure=3&MediaTypes=24&RecipientCountryIDs=3&ReportType=256&ResultType=2&SkipZeroValues=true&SortDirection=1&Years=${year}&_Properties=1`;
    return url;
};

export const linkBuilderCAUS = (lang: string, year: string) => {
    const url = `${globalUrl}/api/GetReport?AgencyIDs=1&Culture=${lang}&IsDrilldown=true&Measure=3&MediaTypes=24&RecipientCountryIDs=3&ReportType=256&ResultType=2&SkipZeroValues=true&SortDirection=1&Years=${year}&_Properties=1`;
    return url;
};

export const linkBuilderUSCA = (lang: string, year: string) => {
    const url = `${globalUrl}/api/GetReport?AgencyIDs=3&Culture=${lang}&IsDrilldown=true&Measure=3&MediaTypes=24&RecipientCountryIDs=1&ReportType=256&ResultType=2&SkipZeroValues=true&SortDirection=1&Years=${year}&_Properties=1`;
    return url;
};

export const linkBuilderUSMX = (lang: string, year: string) => {
    const url = `${globalUrl}/api/GetReport?AgencyIDs=3&Culture=${lang}&IsDrilldown=true&Measure=3&MediaTypes=24&RecipientCountryIDs=2&ReportType=256&ResultType=2&SkipZeroValues=true&SortDirection=1&Years=${year}&_Properties=1`;
    return url;
};

// NAPRT Link Builders
export const linkBuilderTopURL = (
    culture: string,
    queryType: string,
    reportType: string,
    mediaAndSortTypes: string,
    year: string,
    agencyID?: string,
    chemicalTypeID?: string
) => {
    const url = `${globalUrl}/api/GetChartsData?Culture=${culture}&IndustryLevel=4&Measure=3&MediaTypes=${mediaAndSortTypes}&MediaYear=${year}&NumberResults=11&QueryType=${queryType}&ReportType=${reportType}&ResultType=1&SortDirection=2&SortProperty=${mediaAndSortTypes}&SummarizeLast=true&Years=${year}`;
    return url + addAgencyIdsToURL(agencyID) + addChemicalTypeIdsToURL(chemicalTypeID);
};

export const linkBuilderTotalWeight = (lang: string, year: string, agencyID?: string, chemicalTypeID?: string) => {
    const url = `${globalUrl}/api/GetReport?Culture=${lang}&IndustryLevel=4&Measure=3&PageSize=10&ReportType=4&ResultType=1&SortDirection=1&SortProperty=Keys[0].Country.Name&Years=${year}`;
    return url + addAgencyIdsToURL(agencyID) + addChemicalTypeIdsToURL(chemicalTypeID);
};

export const linkBuilderManagement = (
    culture: string,
    queryType: string,
    reportType: string,
    mediaAndSortTypes: string,
    year: string
) => {
    const url = `${globalUrl}/api/GetChartsData?Culture=${culture}&IndustryLevel=4&Measure=3&MediaTypes=${mediaAndSortTypes}&MediaYear=${year}&NumberResults=50&QueryType=${queryType}&ReportType=${reportType}&ResultType=1&SortDirection=2&SortProperty=${mediaAndSortTypes}&SummarizeLast=true&Years=${year}`;
    // calls top 50 responses
    return url;
};

// TPNA Link Builder
export const linkBuilderTPNA = (
    culture: string,
    mediaType: string,
    sortProperty: string,
    year?: string,
    agencyID?: string,
    chemicalTypeID?: string
) => {
    const url = `${globalUrl}/api/GetChartsData?Culture=${culture}&Measure=3&MediaTypes=${mediaType}&NumberResults=6&QueryType=2&ReportType=16&ResultType=1&SortDirection=2&SortProperty=${sortProperty}&SummarizeLast=true`;
    return addYearsToURL(url, year) + addAgencyIdsToURL(agencyID) + addChemicalTypeIdsToURL(chemicalTypeID);
};

export const linkBuilderTPNAAllPollutants = (
    culture: string,
    mediaType: string,
    sortProperty: string,
    year?: string,
    agencyID?: string,
    chemicalTypeID?: string
) => {
    const url = `${globalUrl}/api/GetChartsData?Culture=${culture}&Measure=3&MediaTypes=${mediaType}&QueryType=2&ReportType=16&ResultType=1&SortDirection=2&SortProperty=${sortProperty}&SummarizeLast=true`;
    return addYearsToURL(url, year) + addAgencyIdsToURL(agencyID) + addChemicalTypeIdsToURL(chemicalTypeID);
};

export const topIndustries = (
    culture: string,
    year: string,
    mediaAndSortTypes: string,
    agencyID?: string,
    chemicalTypeID?: string
) => {
    const url = `${globalUrl}/api/GetChartsData?Culture=${culture}&IndustryLevel=4&Measure=3&MediaTypes=${mediaAndSortTypes}&NumberResults=6&QueryType=1&ReportType=2&ResultType=1&SortDirection=2&SortProperty=${mediaAndSortTypes}&SummarizeLast=true&`;
    return addYearsToURL(url, year) + addAgencyIdsToURL(agencyID) + addChemicalTypeIdsToURL(chemicalTypeID);
};

export const topNonCancerPollutants = (
    culture: string,
    mediaType: string,
    sortProperty: string,
    year: string,
    agencyID?: string,
    chemicalTypeID?: string
) => {
    const url = `${globalUrl}/api/GetChartsData?Culture=${culture}&IndustryLevel=4&Measure=3&MediaTypes=${mediaType}&NumberResults=11&QueryType=4&ReportType=16&ResultType=1&SortDirection=2&SortProperty=${sortProperty}&SummarizeLast=true&`;
    return addYearsToURL(url, year) + addAgencyIdsToURL(agencyID) + addChemicalTypeIdsToURL(chemicalTypeID);
};

export const topKnownCarcinogensPollutants = (
    culture: string,
    mediaType: string,
    sortProperty: string,
    year: string,
    agencyID?: string,
    chemicalTypeID?: string
) => {
    const url = `${globalUrl}/api/GetChartsData?Culture=${culture}&IndustryLevel=4&Measure=3&MediaTypes=${mediaType}&NumberResults=11&QueryType=3&ReportType=16&ResultType=1&SortDirection=2&SortProperty=${sortProperty}&SummarizeLast=true&`;
    return addYearsToURL(url, year) + addAgencyIdsToURL(agencyID) + addChemicalTypeIdsToURL(chemicalTypeID);
};

export const facilityCount = (
    culture: string,
    sortProperty: string,
    year: string,
    agencyID?: string,
    chemicalTypeID?: string
) => {
    const url = `${globalUrl}/api/GetReport?Culture=${culture}&IndustryLevel=4&Measure=3&PageSize=10&ReportType=1&ResultType=1&SortDirection=2&SortProperty=${sortProperty}&`;
    return addYearsToURL(url, year) + addAgencyIdsToURL(agencyID) + addChemicalTypeIdsToURL(chemicalTypeID);
};

export const pollutantCount = (
    culture: string,
    sortProperty: string,
    year: string,
    agencyID?: string,
    chemicalTypeID?: string
) => {
    const url = `${globalUrl}/api/GetReport?Culture=${culture}&IndustryLevel=4&Measure=3&PageSize=10&ReportType=16&ResultType=1&SortDirection=1&SortProperty=${sortProperty}&`;
    return addYearsToURL(url, year) + addAgencyIdsToURL(agencyID) + addChemicalTypeIdsToURL(chemicalTypeID);
};

export const getPollutantReportURL = (
    culture: string,
    pollutantID: string,
    mediaType: string,
    year?: string,
    chemicalTypeID?: string,
    agencyID?: string
) => {
    const yearVal = year ?? "";
    const url = `${globalUrl}/Report?ChemicalGroupIDs=${pollutantID}&Culture=${culture}&IndustryLevel=4&IsDrilldown=true&Measure=3&MediaTypes=${mediaType}&QueryType=2&ReportType=16&ResultType=1&WatershedLevel=2&Years=${yearVal}`;
    return url + addAgencyIdsToURL(agencyID) + addChemicalTypeIdsToURL(chemicalTypeID);
};

export const getIndustryReportURL = (
    culture: string,
    mediaType: string,
    industryID: string,
    year?: string,
    chemicalTypeID?: string,
    agencyID?: string
) => {
    const yearVal = year ?? "";
    const url = `${globalUrl}/Report?IndustryIDs=${industryID}&Culture=${culture}&IndustryLevel=4&Measure=3&MediaTypes=${mediaType}&ReportType=2&ResultType=1&WatershedLevel=2&IsDrilldown=true&Years=${yearVal}`;
    return url + addAgencyIdsToURL(agencyID) + addChemicalTypeIdsToURL(chemicalTypeID);
};

// Navbar and OrangeIconSet links

export const getMainDatabaseLink = (culture: string) => {
    return `${globalUrl}/Query?Culture=${culture}&IndustryLevel=4&Measure=3&MediaTypes=29&ReportType=1&ResultType=1&WatershedLevel=2`;
};

export const getCBTLink = (culture: string) => {
    return `${globalUrl}/Query?AgencyIDs=1&Culture=${culture}&IndustryLevel=4&Measure=3&MediaTypes=24&ReportType=256&ResultType=2&SkipZeroValues=true`;
};

export const getMapLink = (culture: string) => {
    return `${globalUrl}/Map?Culture=${culture}`;
};

// prtr links

export const ExcelLink = `${globalUrl}/Content/files/PRTRPollutantsPolluantsRRTPContaminantesRETC.xlsx`;

// Helper functions

const addYearsToURL = (url: string, year?: string) => {
    if (year !== undefined && year !== "") {
        return `${url}&MediaYear=${year.split(",")[0]}&Years=${year}`;
    }
    return url;
};

const addAgencyIdsToURL = (agencyID?: string) => {
    if (agencyID !== undefined && agencyID !== "") {
        return `&AgencyIDs=${agencyID}`;
    }
    return "";
};

const addChemicalTypeIdsToURL = (chemicalTypeID?: string) => {
    if (chemicalTypeID !== undefined && chemicalTypeID !== "") {
        return `&ChemicalTypeIDs=${chemicalTypeID}`;
    }
    return "";
};
