/* eslint-disable no-magic-numbers */
export const version = "2.8.15.3a07de8";
export const years = [
    "allYears",
    2020,
    2019,
    2018,
    2017,
    2016,
    2015,
    2014,
    2013,
    2012,
    2011,
    2010,
    2009,
    2008,
    2007,
    2006
];

