import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Image, Button } from "semantic-ui-react";
import logo from "../images/cec-logo.png";
import Version from "./Version";

export default function FooterDesktop() {
    const { formatMessage } = useIntl();
    return (
        <>
            <footer className="sansFont react-footer">
                <div className="container-div">
                    <div className="footer-note">
                        <FormattedMessage id="footerInfo" />{" "}
                        <a className="footer-note-link" href={formatMessage({ id: "learnMoreLink" })}>
                            <FormattedMessage id="learnMore" />
                        </a>
                    </div>
                    <div className="footer-right-side">
                        <a href={formatMessage({ id: "cecHomePageLink" })}>
                            <Image
                                style={{ maxWidth: "none" }}
                                height={115}
                                width={115}
                                src={logo}
                                alt="Butterflies over the earth, a brand logo incoporating CEC, CCA and CCE"
                            />
                        </a>
                        <section id="contact" />
                        <div className="footer-contact">
                            <h4 className="contact-us sansFont">
                                <FormattedMessage id="contactUs" />
                            </h4>
                            <FormattedMessage id="contactUsDescription" />
                            <span>
                                <FormattedMessage id="email" />:{" "}
                                <a className="contactUsLink" href={`mailto:${formatMessage({ id: "contactUsEmail" })}`}>
                                    <FormattedMessage id="contactUsEmail" />
                                </a>
                            </span>
                            <div className="social-row">
                                <Button
                                    className="first-icon"
                                    circular
                                    color="facebook"
                                    icon="facebook"
                                    as="a"
                                    href={formatMessage({ id: "facebookLink" })}
                                />
                                <Button
                                    className="next-icon"
                                    circular
                                    color="twitter"
                                    icon="twitter"
                                    as="a"
                                    href={formatMessage({ id: "twitterLink" })}
                                />
                                <Button
                                    className="next-icon"
                                    circular
                                    color="youtube"
                                    icon="youtube"
                                    as="a"
                                    href={formatMessage({ id: "youtubeLink" })}
                                />
                                <Button
                                    className="next-icon"
                                    circular
                                    color="linkedin"
                                    icon="linkedin"
                                    as="a"
                                    href={formatMessage({ id: "linkedinLink" })}
                                />
                                <div className="copy-version">
                                    <div className="copyright">
                                        <span>© {new Date().getFullYear()}</span>
                                    </div>
                                    <Version />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}
