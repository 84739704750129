import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { getMapLink } from "../../utilities/Utility";
import orangeDiamond from "../../images/iconwin.png";
import naicon from "../../images/NA_icon.png";
import { CultureContext } from "../../context/CultureContext";

export default function MapLink() {
    const culture = useContext(CultureContext);
    const mapLinkParagraphClassName =
        culture === "en-US" ? "mapLinkParagraphEn" : culture === "fr-CA" ? "mapLinkParagraphFr" : "mapLinkParagraphEs";
    return (
        <div className="ui fluid image orangeIconLink">
            <a href={getMapLink(culture)}>
                <img src={orangeDiamond} alt="Orange link to Main Database" style={{ width: 116, height: 116 }} />
                <div
                    className="iconButton"
                    style={{
                        position: "absolute",
                        bottom: 25,
                        marginLeft: 25,
                        width: "auto",
                        height: "auto"
                    }}
                >
                    <img src={naicon} style={{ width: 60, height: 60 }} />
                </div>
                <p className={mapLinkParagraphClassName}>
                    <FormattedMessage id="mapLink" />
                </p>
            </a>
        </div>
    );
}
