import React from "react";
import { useIntl } from "react-intl";
import { LineGraphDotPayload } from "../Chart";

interface CustomizedLineDotProps {
    cx?: number;
    cy?: number;
    active?: boolean;
    payload?: LineGraphDotPayload;
    labelId: string;
    color: string;
    dataKey: string;
    handleDotClick?: (val: string) => void;
    handleHover?: (val: string, over: boolean, payload?: LineGraphDotPayload, unit?: string) => void;
}

export default function CustomizedLineDot({
    cx,
    cy,
    color,
    dataKey,
    payload,
    labelId,
    handleDotClick,
    handleHover
}: CustomizedLineDotProps) {
    const { formatMessage } = useIntl();
    const allOtherPollutantName = formatMessage({ id: "allOtherPollutants" });
    const allOtherSectorName = formatMessage({ id: "allOtherSectors" });
    const style = dataKey === allOtherPollutantName || dataKey === allOtherSectorName ? {} : { cursor: "pointer" };
    const unit = "kg";
    if (cx === null || cy === null) {
        return null;
    }
    return (
        <circle
            cx={cx}
            cy={cy}
            r="3"
            fill="#FFF"
            strokeWidth="1"
            width="596"
            height="580"
            stroke={color}
            className="recharts-dot"
            style={style}
            onClick={() => (handleDotClick ? handleDotClick(dataKey) : null)}
            onMouseOver={() => (handleHover ? handleHover(dataKey, true, payload, unit) : null)}
            onMouseLeave={() => (handleHover ? handleHover(dataKey, false, payload, unit) : null)}
        ></circle>
    );
}
