import React, { useContext } from "react";
import "./layout.css";
import { Grid, Dropdown, Image, Menu } from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import AnchorLink from "react-anchor-link-smooth-scroll";
import logo from "../images/cec-logo.png";
import I18nLink from "../i18n/Link";
import { getMapLink } from "../utilities/Utility";
import { CultureContext } from "../context/CultureContext";
import Language from "./LanguageNav";
import { cecMenuItems, getCECMenuItem, getFormattedParagraph, getMenuItem, getTsoMenuItems, menuItems } from "./Navbar";

interface Props {
    lang: string;
    path: string;
}

export default function NavbarDesktop({ lang, path }: Props) {
    const { formatMessage } = useIntl();
    const culture = useContext(CultureContext);

    return (
        <>
            <Menu style={{ backgroundColor: "black" }} className="borderless react-header" inverted>
                <Menu.Item style={{ backgroundColor: "black" }}>
                    <a href={formatMessage({ id: "cecHomePageLink" })}>
                        <Image
                            className="react-header-logo"
                            style={{ width: 65 }}
                            src={logo}
                            alt="Butterflies over the earth, a brand logo incoporating CEC, CCA and CCE"
                        />
                    </a>
                </Menu.Item>
                <Menu.Menu position="right" style={{ backgroundColor: "black" }}>
                    <Grid columns={1}>
                        <Grid.Column style={{ fontFamily: "sans-serif" }}>
                            <Menu.Item className="top-row">
                                {menuItems.map((mi, index) => {
                                    return getMenuItem(
                                        formatMessage({ id: mi.hrefId }),
                                        mi.messageId,
                                        index,
                                        mi.menuClassName,
                                        mi.pClassName
                                    );
                                })}
                                <Language lang={lang} path={path} />
                            </Menu.Item>
                            <Menu.Item className="bottom-row">
                                <Menu.Item>
                                    <a href={formatMessage({ id: "newTSReportURL" })}>
                                        {getFormattedParagraph("exploreNewTSReport", "newItem")}
                                    </a>
                                </Menu.Item>
                                <Dropdown
                                    className="simple colorButton header-dropdown-left"
                                    openOnFocus
                                    text={formatMessage({ id: "northAmericanPRTRDB" })}
                                >
                                    <Dropdown.Menu style={{ backgroundColor: "black" }}>
                                        {getTsoMenuItems(culture).map((tsoMI, index) => {
                                            return getMenuItem(tsoMI.hrefId, tsoMI.messageId, index);
                                        })}
                                        <Dropdown.Item as="a" href={getMapLink(culture)}>
                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                {getFormattedParagraph("mapLink", "colorButton")}
                                            </div>
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Dropdown
                                    className="simple colorButton header-dropdown-right"
                                    openOnFocus
                                    text={formatMessage({ id: "understandingTS" })}
                                >
                                    <Dropdown.Menu style={{ backgroundColor: "black" }}>
                                        {cecMenuItems.map((i18n, index) => {
                                            return getCECMenuItem(i18n.hrefId, i18n.messageId, index);
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Menu.Item>
                                    <AnchorLink href="#contact">
                                        {getFormattedParagraph("contact", "colorButton active")}
                                    </AnchorLink>
                                </Menu.Item>
                                <Menu.Item as={I18nLink} to="/">
                                    <p className="colorButton active" style={{ marginTop: -2 }}>
                                        <FormattedMessage id="home" />
                                    </p>
                                </Menu.Item>
                            </Menu.Item>
                        </Grid.Column>
                    </Grid>
                </Menu.Menu>
            </Menu>
        </>
    );
}
