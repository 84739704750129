import React from "react";
import { FormattedMessage } from "react-intl";
import { ImageProps } from "./ImageHoverText";

interface ImageIconsProps {
    andCompounds?: boolean;
    images: ImageProps[];
}

export default function ImageIcons({ images, andCompounds }: ImageIconsProps) {
    return (
        <>
            <p
                style={{
                    fontWeight: "bold",
                    display: "flex",
                    justifyContent: "flex-start"
                }}
            >
                <FormattedMessage id="associated" />
            </p>
            <div className={"info-icon-set info-alignment"}>
                {images.map((i, index) => {
                    return (
                        <div key={index} className={i.className ?? "info-icon-element"}>
                            <img src={i.src} className={"info-icon-image"} alt={i.altName} />
                            <p className={"info-icon-text"}>
                                <FormattedMessage id={i.messageId} />
                            </p>
                        </div>
                    );
                })}
            </div>
            {andCompounds ? (
                <div>
                    <p className={"info-compounds"} style={{ padding: 0 }}>
                        <FormattedMessage id="andCompounds" />
                    </p>
                </div>
            ) : null}
        </>
    );
}
