import React, { useContext } from "react";
import NumberFormat from "react-number-format";
import { Text } from "recharts";
import { CultureContext } from "../../context/CultureContext";

interface CustomizedNumberTickProps {
    x?: number;
    y?: number;
    payload?: { value: number };
    chartType: string;
}

export default function CustomizedNumberTick(props: CustomizedNumberTickProps) {
    const { x, y, payload, chartType } = props;
    const xAxisOffset = -40;
    const adjustedXOffset = -48;
    const yAxisOffset = 5;
    const dx = chartType === "line" ? xAxisOffset : 0;
    const dy = chartType === "line" ? 0 : yAxisOffset;
    const maxDigits = 10;
    const culture = useContext(CultureContext);

    if (payload) {
        const valX = JSON.stringify(payload?.value).length > maxDigits ? adjustedXOffset : dx;
        return (
            <>
                <g transform={`translate(${x ?? 0},${y ?? 0})`}>
                    <NumberFormat
                        value={Math.floor(payload.value)}
                        displayType={"text"}
                        thousandSeparator={culture === "fr-CA" ? " " : true}
                        renderText={(value) => (
                            <Text
                                dx={valX}
                                dy={dy}
                                fontSize={12}
                                textAnchor="middle"
                                verticalAnchor="start"
                                width={115}
                            >
                                {value}
                            </Text>
                        )}
                    />
                </g>
            </>
        );
    }
    return null;
}
