import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { Dropdown } from "semantic-ui-react";
import NumberFormat from "react-number-format";
import uscontinent from "../../images/usContinent3000.png";
import cacontinent from "../../images/caContinent3000.png";
import mxcontinent from "../../images/mxContinent3000.png";
import { CultureContext } from "../../context/CultureContext";
import { getNumberFormat, renderDownArrow, renderUpArrow } from "./CrossBorderTransfersUtils";
import "./CrossBorderTransfers.css";

interface CrossborderTransfersDesktopProps {
    year: string;
    totalForAll: number;
    canadaToUS: number;
    mexicoToUS: number;
    USToCanada: number;
    USToMexico: number;
    yearDescendingOptions: { text: string; value: string }[];
    setCbtYear: (data: string) => void;
}

export default function CrossborderTransfersDesktop({
    year,
    totalForAll,
    canadaToUS,
    mexicoToUS,
    USToCanada,
    USToMexico,
    yearDescendingOptions,
    setCbtYear
}: CrossborderTransfersDesktopProps) {
    const culture = useContext(CultureContext);
    return (
        <>
            <h1 className="cbtTitle">
                <FormattedMessage id="cbtTitle" />
            </h1>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <p style={{ fontSize: 14, marginTop: 20, marginBottom: 10 }}>
                    <FormattedMessage id="cbtAction" />
                </p>
                <div className="cbtYearDropdown">
                    <Dropdown
                        style={{
                            marginTop: 10,
                            marginBottom: 10,
                            backgroundColor: "white",
                            padding: 10,
                            width: 174,
                            borderRadius: 10
                        }}
                        text={year}
                        selection
                        options={yearDescendingOptions}
                        onChange={(e, data) => setCbtYear(String(data.value))}
                    ></Dropdown>
                    <div style={{ margin: 18 }}>
                        <FormattedMessage id="cbtTotal" />
                        (
                        <NumberFormat
                            value={totalForAll}
                            displayType={"text"}
                            thousandSeparator={culture === "fr-CA" ? " " : true}
                        />{" "}
                        kg)
                    </div>
                </div>
            </div>
            <img src={cacontinent} alt="a solid gray color representation of Canada" className="caccontinent" />
            <div className="arrowContainer topArrowContainer">
                <div style={{ display: "flex", justifyContent: "flexStart" }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div>
                            <p style={{ fontSize: 18 }}>
                                <FormattedMessage id="usToCanada" />
                            </p>
                        </div>
                        <div style={{ marginTop: 5 }}>{getNumberFormat(USToCanada, culture)}</div>
                    </div>
                    {renderUpArrow(USToCanada)}
                </div>
                <div style={{ display: "flex", justifyContent: "flexEnd" }}>
                    {renderDownArrow(canadaToUS)}
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div>
                            <p style={{ fontSize: 18 }}>
                                <FormattedMessage id="canadaToUS" />
                            </p>
                        </div>
                        <div style={{ marginTop: 5 }}>{getNumberFormat(canadaToUS, culture)}</div>
                    </div>
                </div>
            </div>
            <img src={uscontinent} alt="a solid color representation of the United States" className="uscontinent" />
            <div className="arrowContainer bottomArrowContainer">
                <div style={{ display: "flex", justifyContent: "flexStart" }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div>
                            <p style={{ fontSize: 18 }}>
                                <FormattedMessage id="mexicoToUS" />
                            </p>
                        </div>
                        <div style={{ marginTop: 5 }}>{getNumberFormat(mexicoToUS, culture)}</div>
                    </div>
                    {renderUpArrow(mexicoToUS)}
                </div>
                <div style={{ display: "flex", justifyContent: "flexEnd" }}>
                    {renderDownArrow(USToMexico)}
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div>
                            <p style={{ fontSize: 18 }}>
                                <FormattedMessage id="usToMexico" />
                            </p>
                        </div>
                        <div style={{ marginTop: 5 }}>{getNumberFormat(USToMexico, culture)}</div>
                    </div>
                </div>
            </div>
            <img src={mxcontinent} alt="a solid color representation of Mexico" className="mxcontinent" />
        </>
    );
}
