import React from "react";
import FooterDesktop from "./FooterDesktop";
import FooterMobile from "./FooterMobile";
import { useViewport } from "./hooks/useViewport";

export default function Footer() {
    const { width, breakpoint } = useViewport();

    return <div>{width < breakpoint ? <FooterMobile /> : <FooterDesktop />}</div>;
}
