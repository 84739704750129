import React from "react";
import { Helmet } from "react-helmet";

interface SEOProps {
    description?: string;
    lang: string;
    keywords: string[];
    title: string;
}

function SEO({ description, lang, keywords, title }: SEOProps) {
    const metaDescription =
        description || "Explore pollution data from across North America with the Taking Stock Interactive";

    return (
        <Helmet
            htmlAttributes={{ lang: lang || "en" }}
            title={title}
            titleTemplate={"%s"}
            meta={[
                {
                    name: "description",
                    content: metaDescription
                },
                {
                    property: "og:title",
                    content: title
                },
                {
                    property: "og:description",
                    content: metaDescription
                },
                {
                    property: "og:type",
                    content: "website"
                },
                {
                    name: "twitter:card",
                    content: "summary"
                },
                {
                    name: "twitter:creator",
                    content: "Commission for Environmental Cooperation"
                },
                {
                    name: "twitter:title",
                    content: title
                },
                {
                    name: "twitter:description",
                    content: metaDescription
                }
            ].concat(
                keywords && keywords.length > 0
                    ? {
                          name: "keywords",
                          content: keywords.join(", ")
                      }
                    : []
            )}
        />
    );
}

export default SEO;
