import React, { useMemo } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { IntlProvider } from "react-intl";
import RedirectPage from "./i18n/redirect";
import FeaturesPage from "./pages/features";
import ScopePage from "./pages/scope";
import GlossaryPage from "./pages/terminology";
import IndexPage from "./pages/index";
import PrtrPage from "./pages/prtr";
import { CultureContext } from "./context/CultureContext";
import { ViewportProvider } from "./context/ViewportContext";

function Pages({ lang }: { lang: string }) {
    const href = window.location.href;

    window.localStorage.setItem("language", lang);
    document.documentElement.lang = lang;

    const messages = require(`./i18n/locales/${lang}.js`);
    const culture = useMemo(() => {
        return lang === "fr" ? "fr-CA" : lang === "en" ? "en-US" : "es-MX";
    }, [lang]);

    return (
        <ViewportProvider>
            <IntlProvider locale={lang} messages={messages}>
                <CultureContext.Provider value={culture}>
                    <Routes>
                        <Route path="/" element={<IndexPage lang={lang} />} />
                        <Route path="features" element={<FeaturesPage lang={lang} href={href} />} />
                        <Route path="prtr" element={<PrtrPage lang={lang} href={href} />} />
                        <Route path="scope" element={<ScopePage lang={lang} href={href} />} />
                        <Route path="terminology" element={<GlossaryPage lang={lang} href={href} />} />
                        <Route path="*" element={<Navigate to={`/${lang}`} />} />
                    </Routes>
                </CultureContext.Provider>
            </IntlProvider>
        </ViewportProvider>
    );
}

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
            <Routes>
                <Route path="en/*" element={<Pages lang="en" />} />
                <Route path="es/*" element={<Pages lang="es" />} />
                <Route path="fr/*" element={<Pages lang="fr" />} />
                <Route path="*" element={<RedirectPage />} />
            </Routes>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById("root")
);
