import React from "react";
import { useIntl } from "react-intl";

export const LoadingSpinner = () => {
    const { formatMessage } = useIntl();
    return (
        <div className="ui segment" style={{ height: 1000, background: "transparent" }}>
            <div className="ui active inverted dimmer" style={{ background: "transparent" }}>
                <div className="ui text loader">{formatMessage({ id: "loading" })}</div>
            </div>
        </div>
    );
};
