import React from "react";
import { Table } from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import "../components/layout.css";
import Share from "../components/Share";
import PageWrapper from "./PageWrapper";

interface FeaturesProps {
    lang: string;
    href: string;
}

interface TableRowData {
    rowHeader: string;
    data: { tri: string; npri: string; retc: string | JSX.Element };
}

export default function Features({ lang, href }: FeaturesProps) {
    const title = useIntl().formatMessage({ id: "featuresThreeNA" });
    const headerIds = ["featurestabler1c1", "featurestabler1c2", "featurestabler1c3", "featurestabler1c4"];

    const retcr3c3 = () => {
        return (
            <>
                <div className="cellBody">
                    <FormattedMessage id="featurestabler3c3" />
                    <ul>
                        <li>
                            <FormattedMessage id="featurestabler3c3bullet1" />
                        </li>
                        <li>
                            <FormattedMessage id="featurestabler3c3bullet2" />
                        </li>
                    </ul>
                </div>
            </>
        );
    };

    const tableRowData: TableRowData[] = [
        {
            rowHeader: "featurestabler2c1",
            data: {
                npri: "featurestabler2c2",
                retc: "featurestabler2c3",
                tri: "featurestabler2c4"
            }
        },
        {
            rowHeader: "featurestabler3c1",
            data: {
                npri: "featurestabler3c2",
                retc: retcr3c3(),
                tri: "featurestabler3c4"
            }
        },
        {
            rowHeader: "featurestabler4c1",
            data: {
                npri: "featurestabler4c2",
                retc: "featurestabler4c3",
                tri: "featurestabler4c4"
            }
        },
        {
            rowHeader: "featurestabler5c1",
            data: {
                npri: "featurestabler5c2",
                retc: "featurestabler5c3",
                tri: "featurestabler5c4"
            }
        },
        {
            rowHeader: "featurestabler6c1",
            data: {
                npri: "featurestabler6c2",
                retc: "featurestabler6c3",
                tri: "featurestabler6c4"
            }
        },
        {
            rowHeader: "featurestabler7c1",
            data: {
                npri: "featurestabler7c2",
                retc: "featurestabler7c3",
                tri: "featurestabler7c4"
            }
        }
    ];
    const getTableHeader = (headerId: string, index: number) => {
        return (
            <Table.HeaderCell style={{ fontSize: 18 }} key={index} className="top aligned">
                <FormattedMessage id={headerId} />
            </Table.HeaderCell>
        );
    };
    const getTableRow = (
        tableRowName: string,
        data: { tri: string; npri: string; retc: string | JSX.Element },
        index: number
    ) => {
        const { tri, npri, retc } = data;
        return (
            <Table.Row key={index}>
                <Table.Cell className="tableCell top aligned">
                    <strong>
                        <FormattedMessage id={tableRowName} />
                    </strong>
                </Table.Cell>
                <Table.Cell className="tableCell top aligned">
                    <div className="cellInfo">
                        <div className="acronym">
                            <FormattedMessage id="npri" />
                        </div>
                        <div className="cellBody">
                            <FormattedMessage id={npri} />
                        </div>
                    </div>
                </Table.Cell>
                <Table.Cell className="tableCell top aligned">
                    <div className="cellInfo">
                        <div className="acronym">
                            <FormattedMessage id="retc" />
                        </div>
                        {typeof retc === "string" ? (
                            <div className="cellBody">
                                <FormattedMessage id={retc} />
                            </div>
                        ) : (
                            retc
                        )}
                    </div>
                </Table.Cell>
                <Table.Cell className="tableCell top aligned">
                    <div className="cellInfo">
                        <div className="acronym">
                            <FormattedMessage id="tri" />
                        </div>
                        <div className="cellBody">
                            <FormattedMessage id={tri} />
                        </div>
                    </div>
                </Table.Cell>
            </Table.Row>
        );
    };
    return (
        <PageWrapper
            lang={lang}
            originalPath="features"
            keywords={["features", "North American PRTRs", "taking stock"]}
            title={title}
        >
            <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                    style={{
                        maxWidth: 900,
                        margin: 50,
                        paddingTop: 50,
                        paddingBottom: 50
                    }}
                >
                    <h1 style={{ fontSize: 36 }}>
                        <FormattedMessage id="featuresThreeNA" />
                    </h1>
                    <p style={{ fontSize: 18 }}>
                        <FormattedMessage id="featuresp1" />
                    </p>
                    <Table celled fixed stackable>
                        <Table.Header>
                            <Table.Row>{headerIds.map((hi, index) => getTableHeader(hi, index))}</Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {tableRowData.map((trd, index) => {
                                return getTableRow(trd.rowHeader, trd.data, index);
                            })}
                        </Table.Body>
                    </Table>
                    <div style={{ margin: 10 }}>
                        <p className="tableCell">
                            <FormattedMessage id="share" />:
                        </p>
                        <Share href={href} />
                    </div>
                </div>
            </div>
        </PageWrapper>
    );
}
