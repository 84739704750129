import React from "react";
import { FormattedMessage } from "react-intl";
import { Container, Divider } from "semantic-ui-react";
import { ChartProvider } from "../context/ChartContext";
import ChartSection from "./ChartComponents/ChartSection";
import "./layout.css";

export default function Reports() {
    return (
        <div style={{ backgroundColor: "#d0cecf", paddingTop: 7 }}>
            <Container>
                <Divider style={{ color: "black", marginTop: -30, marginBottom: 60 }} horizontal>
                    <h3 style={{ backgroundColor: "white", padding: 10 }}>
                        <FormattedMessage id="reports" />
                    </h3>
                </Divider>
                <div>
                    <ChartProvider>
                        <ChartSection type="InPage" />
                    </ChartProvider>
                </div>
            </Container>
        </div>
    );
}
