import React, { useContext } from "react";
import { useIntl } from "react-intl";
import { Icon, Image, Menu, Sidebar } from "semantic-ui-react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { getMapLink } from "../utilities/Utility";
import logo from "../images/cec-logo.png";
import { CultureContext } from "../context/CultureContext";
import Language from "./LanguageNav";
import "./Navbar.css";
import { cecMenuItems, getCECMenuItem, getFormattedParagraph, getMenuItem, getTsoMenuItems, menuItems } from "./Navbar";

interface NavBarMobileProps {
    children: JSX.Element;
    lang: string;
    path: string;
    visible: boolean;
    onPusherClick: () => void;
    onToggle: () => void;
}

export default function NavBarMobile({ children, lang, path, visible, onPusherClick, onToggle }: NavBarMobileProps) {
    const { formatMessage } = useIntl();
    const culture = useContext(CultureContext);

    return (
        <Sidebar.Pushable>
            <Sidebar as={Menu} animation="overlay" direction="right" inverted vertical visible={visible}>
                <Menu.Item>
                    <Language lang={lang} path={path} />
                </Menu.Item>
                {menuItems.map((mi, index) =>
                    getMenuItem(formatMessage({ id: mi.hrefId }), mi.messageId, index, mi.menuClassName, mi.pClassName)
                )}
                <Menu.Item>
                    <AnchorLink href="#contact" onClick={onToggle}>
                        {getFormattedParagraph("contact", "colorButton")}
                    </AnchorLink>
                </Menu.Item>
                <Menu.Item>
                    <a href="./">{getFormattedParagraph("home", "colorButton active")}</a>
                </Menu.Item>
                <Menu.Item>
                    <a href={formatMessage({ id: "newTSReportURL" })}>
                        {getFormattedParagraph("exploreNewTSReport", "newItem")}
                    </a>
                </Menu.Item>
                <Menu.Item>
                    {getFormattedParagraph("northAmericanPRTRDB", "colorButton northAmericanPRTRDB")}
                    <Menu.Menu>
                        {getTsoMenuItems(culture).map((tsoMI, index) =>
                            getMenuItem(tsoMI.hrefId, tsoMI.messageId, index, tsoMI.menuClassName)
                        )}
                        <Menu.Item style={{ paddingLeft: 30 }} as="a" href={getMapLink(culture)}>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                {getFormattedParagraph("mapLink", "colorButton")}
                            </div>
                        </Menu.Item>
                    </Menu.Menu>
                </Menu.Item>
                <Menu.Item>
                    {getFormattedParagraph("understandingTS", "colorButton")}
                    <Menu.Menu>
                        {cecMenuItems.map((i18n, index) => getCECMenuItem(i18n.hrefId, i18n.messageId, index))}
                    </Menu.Menu>
                </Menu.Item>
            </Sidebar>
            <Sidebar.Pusher dimmed={visible} onClick={onPusherClick} style={{ minHeight: "100vh" }}>
                <Menu style={{ backgroundColor: "black" }} fixed="top" inverted>
                    <Menu.Item>
                        <Image
                            style={{ height: 65, width: 65, marginLeft: 32 }}
                            src={logo}
                            alt="Butterflies over the earth, a brand logo incoporating CEC, CCA and CCE"
                        />
                    </Menu.Item>
                    <Menu.Item onClick={onToggle} position="right">
                        <Icon name="sidebar" color="teal" size="big" />
                    </Menu.Item>
                </Menu>
                {children}
            </Sidebar.Pusher>
        </Sidebar.Pushable>
    );
}
