module.exports = {
    title: "EN",
    understandingTS: "Understanding Taking Stock",
    introTitleOne: "electronics",
    introTextOne: "The production of electronic goods can generate large amounts of potentially hazardous waste.",
    introTitleTwo: "fossil fuels",
    introTextTwo: "Emissions from fossil fuel combustion can generate acidic gases and cause respiratory problems.",
    introTitleThree: "food production",
    introTextThree:
        "Pollutants released to water from food production and processing can contribute to eutrophication, or oxygen-poor water bodies.",
    buttonHeader:
        "Explore analyses of North American PRTR data and related information through the Taking Stock report series.",
    associated: "Examples of associated pollutants:",
    reports: "DATA OVERVIEW",
    tpnaTitle: "Top Pollutants (by amount)",
    tpnaExportTitle: "TopPollutantsbyAmount",
    tpnaSummary1: "Total, All Reported Pollutants:",
    cbtTitle: "Cross-border Transfers",
    cbtAction: "Select a year to see the amounts transferred between countries.",
    usToCanada: "Total US to Canada",
    usToMexico: "Total US to Mexico",
    canadaToUS: "Total Canada to US",
    mexicoToUS: "Total Mexico to US",
    footerCallToAction: "Want to dig deeper?",
    footerSecondary:
        "Explore releases and transfers reported by approximately 40,000 industrial facilities across North America – by year, pollutant, facility, sector, country, or region (including cross-border transfers).",
    totalReleases: "Total Releases and Transfers",
    releasesLand: "On-Site Disposal or Land Releases",
    transfersRecycling: "Transfers To Recycling",
    releasesAir: "On-Site Air Emissions",
    releasesWater: "On-Site Surface Water Discharges",
    disposal: "Transfers to Disposal*",
    undergroundInjection: "On-Site Underground Injection",
    furtherManagement: "Transfers to Further Management**",
    disposalFooter: "* “Transfers to Disposal” includes the following sub-categories:",
    disposalSubCatLandfill: "a) to Landfill or Surface Impoundment",
    disposalSubCatUndergroundInjection: "b) to Underground Injection",
    disposalSubCatLandApplication: "c) to Land Application",
    disposalSubCatStabilization: "d) to Stabilization or Treatment Prior to Disposal",
    disposalSubCatStorage: "e) to Storage Prior to Disposal",
    disposalSubCatOther: "f) to Other Disposal",
    tpnaFooter: "** “Transfers to Further Management” includes the following sub-categories:",
    furtherManagementTreatment: "a) to Treatment",
    furtherManagementSewage: "b) to Sewage/WWTP",
    furtherManagementEnergy: "c) to Energy Recovery",
    mainDatabaseLink: "Query the main database",
    cbtLink: "Explore cross-border transfers",
    cbtTotal: "Total Cross-border Transfers:",
    mapLink: "Map the data",
    actionPlan: "Action Plan",
    tsoReport: "Taking Stock Reports",
    prtrReportReqs: "PRTR Reporting Requirements",
    prtrIntro:
        "A Pollutant Release and Transfer Register (PRTR) provides annual data on the pollutants released by facilities on site to the air, water and land, or for disposal or underground injection; and transferred off site for recycling, treatment or disposal. The CEC’s North American PRTR (NAPRTR) initiative promotes public access to PRTR data to improve understanding of the sources and management of pollutants of common concern. The data used in Taking Stock are reported by facilities to the three national PRTRs: Canada’s {link1}, Mexico’s {link2}, and the United States’ {link3}. Efforts are focused on adding value to the data through their integration, analysis and dissemination.",
    prtrIntrolink1: "National Pollutant Release Inventory (NPRI)",
    prtrIntrolink2: "Registro de Emisiones y Transferencia de Contaminantes (RETC)",
    prtrIntrolink3: "Toxics Release Inventory (TRI)",
    prtrtab1title: "Which Pollutants Must Be Reported?",
    prtrtab1link: "Download list",
    prtrtab1p1:
        "The pollutants subject to national PRTR reporting requirements meet certain criteria for chemical toxicity and risk to human health and the environment. Each PRTR system covers a specific list of substances: NPRI spans more than 320 pollutants; TRI more than 700; and since 2014, RETC covers 200 pollutants. Each program also includes pollutant reporting thresholds based on whether the substance is manufactured, processed, or otherwise used (MPO), or released. Certain pollutants have lower reporting thresholds due to their greater potential for risk to human health and the environment.",
    prtrtab1p2: "For more information, see: {featuresLink}",
    prtrtab1p3: "To see the list of pollutants reported to the North American PRTRs, please consult: {link1}.",
    prtrtab1p3link: "[Excel File]",
    prtrtab1p4:
        "In order to provide more information about PRTR pollutants, the Taking Stock report and online database also categorize them as follows:",
    prtrtab1bullet3:
        "Known or suspected carcinogens, based on the World Health Organizations’ International Agency for Research on Cancer (IARC) and California’s Office of Environmental Health Hazard Assessment (OEHHA) Proposition 65 list;",
    prtrtab1bullet4: "Developmental or reproductive toxicants, based on California’s Proposition 65 list;",
    prtrtab1bullet5:
        "Persistent, bioaccumulative and toxic (PBT) substances, which have properties that render them a long-term environmental and health threat, even in small quantities;",
    prtrtab1bullet6:
        "Metals: Metals occur naturally, but human activities such as mining and smelting enlarge their proportions in the environment. The toxicity of certain metals and their compounds can depend on the forms they take in the environment.",
    prtrtab2title: "Toxic Equivalency Potentials (TEP) for Air and Water Releases",
    prtrtab2p1:
        "Toxic Equivalency Potentials (TEPs) indicate the relative human health risk associated with a release of one unit of a pollutant, compared to the risk posed by the release of one unit of a reference substance. TEPs are calculated using the CalTOX model, developed by California regulatory agencies. TEPs are one of many different screening tools, each of which is based on a series of assumptions, thus yielding different results. The TEPs have been taken from Scorecard. The risk assessment information used in Taking Stock is revised and updated from time to time.",
    prtrtab2p2:
        "Readers should note that the TEP analysis is limited in that a release does not directly correlate to actual exposures or levels of risk. In addition, not all of the substances have an assigned TEP (information on their toxicity or exposure potential may be missing). While these pollutants are not ranked by TEP, they should not be assumed to be without risk. Other types of releases not ranked by TEP (e.g., land) should also not be assumed to be without risk.",
    prtrtab2p3:
        "TEPs are available only for air and water releases. The reference chemical for carcinogens is benzene and the reference chemical for non-carcinogens is toluene. In the Taking Stock report and database, the TEP weight is multiplied by the amount of release to provide a score for each pollutant.",
    prtrtab3title: "Reporting of Criteria Air Contaminants and Greenhouse Gases",
    prtrtab3p1:
        "Data for releases of criteria air contaminants (CACs) and greenhouse gases (GHGs) are not included in Taking Stock, due to differences in national reporting requirements for these pollutants. CACs—including carbon monoxide, nitrogen oxides, particulate matter, sulfur oxides and volatile organic compounds—are a group of chemicals associated with environmental effects such as smog, acid rain and regional haze, and health effects such as respiratory illnesses. Major sources of CACs are the burning of fossil fuels, as well as natural resource extraction and a variety of manufacturing activities. GHGs contribute to climate change by trapping heat within the earth’s atmosphere. They are the subject of the international Kyoto Protocol, which came into force in 2005. The major GHGs include carbon dioxide, methane, nitrous oxide and three groups of fluorinated gases. Some of the main anthropogenic sources of GHGs are the burning of fossil fuels, deforestation and agricultural activities. CACs are reported to Canada’s NPRI and GHGs are reported to Mexico’s RETC, but these pollutants are not subject to US TRI reporting. However, there are other sources of information on emissions of these pollutants in all three countries:",
    prtrtab3list1: "Criteria Air Contaminants:",
    prtrtab3bullet1: "Canada’s National Pollutant Release Inventory (NPRI)",
    prtrtab3bullet2: "U.S. National Emissions Inventory (NEI)",
    prtrtab3bullet3: "Mexico’s Inventario Nacional de Emisiones de Contaminantes Criterio (INEM)",
    prtrtab3list2: "Greenhouse Gases:",
    prtrtab3bullet4: "Canada’s Greenhouse Gas Reporting Program",
    prtrtab3bullet5: "U.S. Greenhouse Gas Reporting Program",
    prtrtab3bullet6: "Mexico’s Registro de Emisiones y Transferencia de Contaminantes (RETC)",
    prtrtab3bullet7: "Mexico’s Inventario Nacional de Emisiones de Gases y Compuestos de Efecto Invernadero",
    prtrtab4title: "Which Industries Report?",
    prtrtab4p1:
        "Each country requires PRTR reporting by facilities in specific industrial sectors or undertaking specific industrial activities.",
    prtrtab4bullet1:
        "In Canada, all facilities that meet reporting thresholds and requirements report to the NPRI, with the exception of a few resource-based sectors and certain activities such as research laboratories.",
    prtrtab4bullet2:
        "In Mexico, all industrial sectors regulated under federal law are required to report to the RETC, along with facilities in other sectors that engage in activities subject to federal regulation. These include facilities that handle hazardous wastes, or discharge pollutants into national water bodies.",
    prtrtab4bullet3:
        "In the United States, TRI requires reporting by federal facilities, most manufacturing facilities and industries that service manufacturing facilities (e.g., electric utilities and hazardous waste management facilities). A few resource-based sectors, such as oil and gas extraction, are exempt from reporting.",
    prtrtab5title: "North American Industry Classification System",
    prtrtab5p1:
        "Canada, Mexico and the United States have adopted the North American Industry Classification System (NAICS), whose codes are used to categorize the industrial activities of a facility. NAICS codes were established in 1997 and since 2006 they have been incorporated into PRTR reporting to replace the standard industrial classification (SIC) codes used by each country. Although there is some variation among the three countries in the subsector categorizations and codes used, the breakdown of industrial sectors into general categories is the same. For more information about the implementation of the NAICS system in each country, see:",
    prtrtab5tabletitle1: "NAICS code",
    prtrtab5tabletitle2: "Industry",
    prtrtabler1: "Agriculture, forestry, fishing and hunting",
    prtrtabler2: "Mining, quarrying and oil and gas extraction",
    prtrtabler3: "Utilities (electricity, water and gas distribution)",
    prtrtabler4: "Construction",
    prtrtabler5: "Manufacturing",
    prtrtabler6: "Wholesale trade",
    prtrtabler7: "Retail trade",
    prtrtabler8: "Transportation and warehousing",
    prtrtabler9: "Information and cultural industries",
    prtrtabler10: "Finance and insurance",
    prtrtabler11: "Real estate and rental and leasing",
    prtrtabler12: "Professional, scientific and technical services",
    prtrtabler13: "Management of companies and enterprises",
    prtrtabler14: "Administrative and support, waste management and remediation services",
    prtrtabler15: "Educational services",
    prtrtabler16: "Health care and social assistance",
    prtrtabler17: "Arts, entertainment and recreation",
    prtrtabler18: "Accommodation and food services",
    prtrtabler19: "Other services (except public administration)",
    prtrtabler20: "	Public administration",
    prtrtab5p2:
        "PRTR reporting requirements are based in part on the industrial activity undertaken within a facility, and not only the industry code assigned to that facility. Therefore, not all facilities within a given sector might have to report. For example, within the economic sector that includes dry-cleaning only those facilities undertaking the actual dry-cleaning process, and not clothing drop-off points, might be required to report. Another example is a food processing plant that is required to report because it has its own power plant to generate electricity.",
    prtrtab6title: "Employee Thresholds",
    prtrtab6p1:
        "Both NPRI and TRI have an employee threshold, generally corresponding to the equivalent of 10 full-time employees (with some exceptions for pollutants or certain types of facilities). Mexico’s RETC does not have an employee threshold. More information on reporting instructions is available on the NPRI, RETC and TRI websites.",
    tssmtitle: "Scope and Methodology used in Taking Stock",
    tssmp1: "Data from the three countries’ PRTRs were retrieved by the CEC from the three governments or from their publicly accessible websites.",
    stylep2: "Note:",
    tssmp2: "{style} The data sets of the national PRTR systems are constantly evolving as facilities revise previous submissions to correct reporting errors or make other changes.",
    tssmp3link: "CAC/GHG",
    tssmp3: "With the exception of criteria air contaminants (CAC) and greenhouse gases (GHG), all reported data from the three countries are accessible through the Taking Stock Online integrated NAPRTR database. CAC are reported to Canada’s NPRI and GHG are reported to Mexico’s RETC, but these pollutants are not subject to US TRI reporting. However, there are other sources of information on emissions of these pollutants in all three countries, which users can consult for more details: {link1}.",
    liststyle1: "Taking Stock",
    tssmlist1:
        "The methodology used in preparation of the annual {style} report and online database includes the following:",
    tssmbullet1link: "(see the Terminology)",
    tssmbullet1:
        "The PRTR data from each country are compiled for the {style} integrated, North American PRTR database. This involves standardizing data fields used in the three countries {link1}.",
    tssmbullet2:
        "Certain individual reported substances are aggregated into pollutant groups or categories (e.g., lead and its compounds, xylene isomers). In these cases, no specific CAS number for the pollutant group is assigned.",
    tssmbullet3:
        "The data are submitted to a general review in order to identify inconsistencies or possible errors, which are then communicated to the national PRTR programs. Although the CEC cannot be responsible for erroneous reporting by facilities, the goal of the North American PRTR project is to use the best data possible.",
    tssmbullet4:
        "Data for each reporting year (going back to 2006) are refreshed at least annually – a fact that readers are urged to remember, particularly when attempting to use the data to analyze time trends. Users can visit the national websites to view changes to the data.",
    tssmheader1: "Limitations of the PRTR Data",
    tssmp4: "Because of national PRTR reporting requirements, including thresholds for pollutants and facilities, only a portion of all industrial pollution is being captured. Also, industrial facilities are not the only sources of pollution in North America.",
    tssmlist2: "North American PRTR data do not provide information on:",
    stylebullet5: "All potentially harmful substances.",
    tssmbullet5: "{style} The data provide information only on the pollutants reported to each country’s PRTR.",
    stylebullet6: "All sources of contaminants.",
    tssmbullet6:
        "{style} The report includes only those facilities in the countries’ industrial sectors, or undertaking specific industrial activities, that are subject to reporting to each national PRTR program. The North American PRTRs do not include emissions from automobiles or other mobile sources, from natural sources such as forest fires or from agricultural sources. For some pollutants, these mobile, natural and agricultural sources can be large contributors to the overall amounts.",
    stylebullet7: "Releases and transfers of all pollutants from a facility.",
    tssmbullet7: "{style} Only those pollutants for which reporting thresholds are met are included.",
    stylebullet8: "All facilities within required reporting sectors.",
    tssmbullet8:
        "{style} In Canada and the United States, only facilities with the equivalent of 10 full-time employees must report (with certain exceptions). Mexico has no employee threshold.",
    stylebullet9: "Environmental fate of or risks",
    tssmbullet9: "{style} from the pollutants released or transferred.",
    stylebullet10: "Levels of exposure",
    tssmbullet10: "{style} of human or ecological populations to the pollutants.",
    stylebullet11: "Legal limits",
    tssmbullet11:
        "{style} of a pollutant from a facility. The data do not indicate whether a facility is in compliance with permits and other regulations.",
    tssmp5: "Substances released or transferred by industrial facilities have physical and chemical characteristics that influence their ultimate disposition and consequences for human and ecological health. Assessing the potential harm from particular releases of a pollutant to the environment is a complex task because the potential of a substance to cause harm arises from various factors, including its inherent toxicity and the nature of the exposure to the substance (e.g., the potential risk posed by asbestos sent to a secure landfill is considered to be much lower than the risk posed by asbestos released to air).",
    tssmp6: "PRTR data alone cannot provide enough information to assess the potential harm from a pollutant; however, the data in combination with other information about a pollutant can serve as a starting point for learning more about its potential impacts. Readers may wish to other sources for more information, including:",
    tssmbullet12link: "ToxFAQs",
    tssmbullet12: "{link1}, US Agency for Toxic Substances and Disease Registry",
    tssmbullet13link: "Right-to-Know Hazardous Substances Fact Sheets",
    tssmbullet13: "State of New Jersey, Department of Health, {link1} (information also available in Spanish)",
    featuresp1:
        "Taking Stock is based on information provided by North America’s three national PRTR programs. Each country’s PRTR has evolved with its own list of pollutants, sector coverage, and reporting requirements. The table below compares features of the three PRTRs.",
    featurestabler1c1: "Feature",
    featurestabler1c2: "Canada’s National Pollutant Release Inventory (NPRI)",
    featurestabler1c3: "Mexico’s Registro de Emisiones y Transferencia de Contaminantes (RETC)",
    featurestabler1c4: "US Toxics Release Inventory (TRI)",
    featurestabler2c1: "First mandatory reporting year",
    featurestabler2c2: "1993",
    featurestabler2c3: "2004",
    featurestabler2c4: "1987",
    featurestabler3c1: "Industrial sectors and activities covered",
    featurestabler3c2:
        "Any facility manufacturing or using a listed chemical, except for exempted activities (e.g., research, repair, retail sale, agriculture, and forestry). Any facility releasing criteria air contaminants (CACs) to air in specified quantities.",
    featurestabler3c3:
        "Point sources in eleven sectors under federal jurisdiction, in terms of atmospheric emissions: petroleum, chemical and petrochemical industries; paints and inks; metallurgy (iron and steel); automobile manufacturing; pulp and paper; cement and lime; asbestos; glass; power plants; and hazardous waste management facilities. Also, facilities engaged in the following activities subject to reporting under federal jurisdiction:",
    featurestabler3c3bullet1:
        "Large generators (10 tons or more) of hazardous waste (if the transferred wastes contain PRTR substances in amounts equal to or greater than the reporting threshold)",
    featurestabler3c3bullet2:
        "Facilities that discharge wastewater into national water bodies (if the wastewater contains PRTR substances in amounts equal to or greater than the reporting threshold).",
    featurestabler3c4:
        "Manufacturing and federal facilities, electric utilities (oil- and coal-fired), coal and metal mines, hazardous waste management and solvent recovery facilities, chemical wholesalers, and petroleum bulk terminals.",
    featurestabler4c1: "Number of pollutants subject to reporting",
    featurestabler4c2: "More than 320 pollutants/pollutant groups.",
    featurestabler4c3: "200 pollutants/pollutant groups (as of 2014).",
    featurestabler4c4: "More than 700 individual pollutants and 33 chemical categories.",
    featurestabler5c1: "Employee threshold",
    featurestabler5c2:
        "Generally, 10 employees or more. For certain activities, such as waste incineration and wastewater treatment, the 10-employee threshold does not apply.",
    featurestabler5c3: "No employee threshold.",
    featurestabler5c4: "Ten or more full-time employees, or the equivalent in hours worked.",
    featurestabler6c1: "Pollutant “activity” (manufacture, process, or other use), or release thresholds",
    featurestabler6c2:
        "“Activity” thresholds of 10,000 kg for most chemicals. Lower thresholds for certain pollutants such as PBTs, polycyclic aromatic hydrocarbons, dioxins and furans, and criteria air contaminants.",
    featurestabler6c3:
        "“Release” and “Activity” thresholds for each pollutant (facilities must report if they meet or exceed either threshold). Except for GHG, release thresholds range from 1 to 1,000 kg and activity thresholds range from 5 to 5,000 kg. Any release of polychlorinated biphenyls or sulfur hexafluoride, and any release or other activity involving dioxins and furans, must be reported.",
    featurestabler6c4:
        "“Activity” thresholds of 25,000 lbs (11,340 kg), with an “other use” threshold of 10,000 lbs (about 5,000 kg). Lower thresholds for certain substances, such as PBTs and dioxins and furans.",
    featurestabler7c1: "Types of releases and transfers covered",
    featurestabler7c2:
        "On-site releases to air, water, land; disposal (including underground injection). Off-site transfers to disposal, treatment prior to disposal (including sewage), recycling, and energy recovery.",
    featurestabler7c3:
        "On-site releases to air, water, and land. Off-site transfers to disposal, recycling, reuse, energy recovery, treatment, co-processing, and discharges to sewer/sewage treatment.",
    featurestabler7c4:
        "On-site releases to air, water, land, and underground injection. Off-site transfers to disposal, recycling, energy recovery, treatment, and wastewater treatment.",
    glossarytitle: "Terminology",
    glossaryp1: "{style} uses the following categories for presenting information on pollutant releases and transfers.",
    boldlist1: "On-Site releases or disposals",
    glossarylist1: "{style} occur at a facility. These include:",
    glossarybullet1: "Releases to air",
    glossarybullet2: "Releases to surface water",
    glossarybullet3: "Releases to underground injection",
    glossarybullet4: "Disposals or land releases.",
    boldp2: "Off-Site disposal:",
    glossaryp2:
        "{style} describes pollutants sent to off-site locations for disposal (e.g., landfill or surface impoundment; underground injection).",
    glossaryp3: "{style} describes substances sent off-site for recycling.",
    boldp4: "Transfers for further management",
    glossaryp4:
        "{style} includes pollutants (other than metals*) sent off-site for treatment, energy recovery, or to sewage.",
    stylep5: "*A note about metals:",
    glossaryp5:
        "{style} Quantities of metals reported as sent off-site for sewage, treatment or energy recovery are included in the off-site disposal category – since these metals may be captured and removed from waste and disposed of in landfills or by other methods. This approach recognizes the physical nature of metals and the fact that they are not destroyed through treatment.",
    stylep6: "Note:",
    glossaryp6:
        "{style} Because this terminology is specific to Taking Stock, the terms release and transfer as defined here may differ from their use in NPRI, RETC and TRI.",
    featuresThreeNA: "Features of the Three North American PRTRs",
    tsScopeMethod: "Taking Stock Scope and Methodology",
    share: "Share this on",
    about: "About",
    contact: "Contact",
    manganese: "Manganese*",
    chromium: "Chromium*",
    lead: "Lead*",
    hydrochloricAcid: "Hydrochloric Acid",
    barium: "Barium*",
    mercury: "Mercury*",
    nitricAcid: "Nitric Acid/ Nitrate Compounds",
    totalPhosphorous: "Total Phosphorus",
    sodiumNitrite: "Sodium Nitrite",
    andCompounds: "* and its compounds",
    hoverText1:
        'Differences among national reporting requirements for pollutants and sectors must be taken into account when interpreting North American PRTR data. For more information, see "Understanding Taking Stock."',
    hoverText2:
        "Each PRTR system covers a specific list of substances: NPRI approximately 350; TRI approximately 650; and RETC, 200.",
    footerInfo:
        "The CEC's North American Pollutant Release and Transfer Register (PRTR) Initiative promotes public access to data and information reported by industrial facilities in Canada, Mexico and the United States to enhance the understanding of the sources and management of pollutants of common concern and support decisions relative to pollution prevention and sustainability.",
    contactUs: "CONTACT US",
    contactName: "CEC Secretariat",
    contactAddress: "700 de la Gauchetière St. West, Suite 1620",
    contactCity: "Montreal (Quebec), H3B 5M2",
    contactPhone: "Phone",
    contactFax: "Fax",
    kilograms: "kilograms",
    home: "Home",
    tri: "TRI",
    npri: "NPRI",
    retc: "RETC",
    export: "Export",
    northAmericanPRTRDB: "North American PRTR Database",
    learnMore: "Learn More.",
    learnMoreLink: "http://www.cec.org/pollutants/tracking-pollutant-releases-and-transfers-in-north-america/",
    actionPlanLink:
        "http://www.cec.org/publications/action-plan-to-enhance-the-comparability-of-pollutant-release-and-transfer-registers-prtrs-in-north-america/",
    tsoReportLink: "http://www.cec.org/publications/?_series=taking-stock-series",
    cecHomePageLink: "http://www.cec.org/",
    tsoTitle: "CEC - Taking Stock",
    industryTitle: "Top Industries (by amount)",
    industryExportTitle: "TopIndustries",
    industrySummary: "Total, All Reported Industries:",
    takingStockSummaryIntro:
        "Along with the energy, materials, and items we use every day, North American industry sectors generate pollutants that must be managed to protect our health and environment. Each year, the substances released to air, water, or land, sent to disposal, or transferred to recycling or other management are reported by industrial facilities to the Canadian, Mexican and U.S. pollutant release and transfer registers (PRTRs). The CEC's North American PRTR Initiative brings together the data for approximately 40,000 industrial facilities across the region",
    takingStockSummaryEnd:
        "and makes them accessible via the Taking Stock Online website and searchable database, with added information and analyses in the Taking Stock report series. In this way, the CEC promotes enhanced access and understanding of the amounts, sources and management of industrial pollutants and support decisions about pollution prevention and sustainability.",
    readMore: "[+]",
    readLess: "[-]",
    interactiveGraphicTitle:
        "Do you want to know more about North American industrial pollutant releases and transfers?",
    interactiveGraphicText:
        "Click on one of the categories in the picture below, then use the filters in the associated charts to explore the data available for approximately 40,000 facilities across the region.",
    filtersSelected: "Filters Selected",
    mediaType: "Release or Transfer Type",
    country: "Country",
    pollutantType: "Pollutant Type",
    facilityCount: "Facility Count",
    pollutantCount: "Pollutant Count",
    loading: "Loading...",
    allOtherPollutants: "All Other Pollutants",
    year: "Year",
    northAmerica: "North America",
    canada: "Canada",
    mexico: "Mexico",
    unitedStates: "United States",
    allPollutantTypes: "All Pollutant Types",
    devReproToxins: "Developmental or Reproductive Toxicants",
    knownOrSusCarc: "Known or Suspected Carcinogens",
    metals: "Metals",
    persBioToxic: "Persistent, Bioaccumulative, Toxic Pollutants (PBT)",
    allYears: "All Years",
    results: "Results",
    clickBarPollutantExplore: "Click on a bar to explore data for this pollutant in the database.",
    clickBarIndustryExplore: "Click on a bar to explore data for this industry sector in the database.",
    for: "for",
    allOtherSectors: "All Other Sectors",
    clickDotIndustryExplore: "Click on a dot to explore data for this industry sector in the database.",
    clickDotPollutantExplore: "Click on a dot to explore data for this pollutant in the database.",
    industryText: "Industry sectors are displayed at the NAICS-4 level.",
    impconTpna1Title: "PRTR Reporting Requirements Relative to Pollutants",
    impconTpna1part1: "Each country has unique reporting requirements relative to pollutants:",
    impconTpna1part1ca: "CANADA'S NPRI:",
    impconTpna1part1ca1: "more than 320 substances",
    impconTpna1part1mx: "MEXICO'S RETC:",
    impconTpna1part1mx1: "200 substances (prior to 2014, there were 104)",
    impconTpna1part1us: "UNITED STATES' TRI:",
    impconTpna1part1us1: "more than 700 substances (and 33 chemical categories)",
    impconTpna1part2:
        "Each program also includes pollutant reporting thresholds, with some pollutants having lower thresholds due to their greater potential to cause harm to human health and/or the environment.",
    impconTpna1part3: "To see the list of substances reported to the North American PRTRs:",
    impconTpna2Title: "Pollutant Types in Taking Stock",
    impconTpna2part1: "Certain substances in Taking Stock are categorized as one or more of the following:",
    impconTpna2part2:
        "Note: Data for releases of criteria air contaminants (CAC) and greenhouse gases (GHG) are not included in Taking Stock due to differences among national PRTR reporting requirements for these pollutants.",
    impconTpna3Title: "Factors to Consider When Using PRTR Data to Evaluate Risk",
    impconTpna3part1:
        "In addition to the amount released or transferred, other factors need to be taken into consideration when trying to assess whether a substance poses a risk to human health or the environment. These include the pollutant's inherent toxicity, its potential to persist in the environment or alter it in some way, the medium of release, level of exposure; etc.",
    impconTpna3part2: "Toxicity Equivalency Potentials (TEP):",
    impconTpna3part3: "For certain pollutants released to air or water,",
    impconTpna3part4:
        "Taking Stock also provides TEP scores, which indicate a substance's potential to cause cancer or other (non-cancer) effects.",
    impconNaics1Title: "North American Industry Classification System (NAICS) Codes",
    impconNaics1part1:
        "The North American Industry Classification System (NAICS) was developed by the statistical agencies of Canada, Mexico, and the United States in 1997. The structure of NAICS is hierarchical and involves a six-digit code (the first two digits designate the sector, the third designates the subsector, and so on). NAICS codes were incorporated into PRTR reporting in 2006 and Taking Stock Online users can query data at the NAICS-3, NAICS-4, and NAICS-5 levels.",
    impconNaics2Title: "PRTR Reporting Requirements Relative to Industry Sectors",
    impconNaics2part1:
        "North American PRTR data reflect the size and composition of each country's industrial base, as well as differences among national PRTR reporting requirements relative to industrial sectors and activities:",
    impconNaics2part1ca1:
        "All facilities that meet reporting thresholds and requirements must report, with a few exceptions (e.g., research facilities).",
    impconNaics2part1mx1:
        "Eleven federally regulated sectors, along with facilities in other sectors that engage in activities subject to federal regulation (e.g., facilities that handle hazardous wastes or discharge pollutants into national water bodies) are required to report.",
    impconNaics2part1us1:
        "Federal facilities, most manufacturing facilities, and industries that service manufacturing facilities (e.g., electric utilities, hazardous waste management facilities) must report. A few resource-based sectors (e.g., oil and gas extraction) are exempt.",
    impconNaics2part2:
        "With certain exceptions, only those Canadian and US facilities that meet the employee threshold (generally, 10 full-time employees or the equivalent in hours worked) are subject to PRTR reporting.",
    exploreNewTSReport: "Explore the new Taking Stock report!",
    newTSReportURL: "http://www.cec.org/tsreports/",
    facebookLink: "https://www.facebook.com/CECconnect",
    npriLink:
        "https://www.canada.ca/en/services/environment/pollution-waste-management/national-pollutant-release-inventory.html",
    openDataCanadaLink: "https://open.canada.ca/data/en/dataset/a8ba14b7-7f23-462a-bdbb-83b0ef629823",
    rightToKnowLink: "http://web.doh.state.nj.us/rtkhsfs/indexFs.aspx",
    twitterLink: "https://twitter.com/CECweb",
    youtubeLink: "https://www.youtube.com/user/CECweb",
    linkedinLink: "https://www.linkedin.com/company/commission-for-environmental-cooperation-of-north-america",
    retcLink: "http://apps1.semarnat.gob.mx/retc/index.html",
    triLink: "https://www.epa.gov/toxics-release-inventory-tri-program",
    neiLink: "https://www.epa.gov/air-emissions-inventories/2014-national-emissions-inventory-nei-data",
    inemLink:
        "https://www.gob.mx/semarnat/acciones-y-programas/inventario-nacional-de-emisiones-de-contaminantes-criterio-inem",
    ghgLink: "https://www.epa.gov/ghgreporting",
    retcGHGLink: "https://apps1.semarnat.gob.mx:8443/retc/index.html",
    ineccLink:
        "https://www.gob.mx/inecc/acciones-y-programas/inventario-nacional-de-emisiones-de-gases-y-compuestos-de-efecto-invernadero",
    usNAICSLink: "https://www.census.gov/naics/",
    mexNAICSLink: "http://www.inegi.org.mx/app/biblioteca/ficha.html?upc=702825099701",
    toxFAQLink: "http://www.atsdr.cdc.gov/toxfaqs/index.asp",
    email: "Email",
    contactUsEmail: "NAPRTR@cec.org",
    contactUsDescription: "North American Pollutant Release and Transfer Register (PRTR) Initiative",
    giveFeedbackLink:
        "https://docs.google.com/forms/d/e/1FAIpQLSdyhI0qZrqyQhbhhFSf9rP-Z2quSJEZwA-cw1a0OT8-otcbgA/viewform",
    giveFeedback: "Give us your feedback"
};
