import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Image, Button } from "semantic-ui-react";
import logo from "../images/cec-logo.png";
import Version from "./Version";

export default function FooterMobile() {
    const { formatMessage } = useIntl();
    return (
        <>
            <div className="mobile-footer">
                <div className="mobile-footer-info">
                    <FormattedMessage id="footerInfo" />{" "}
                    <a className="footer-note-link" href={formatMessage({ id: "learnMoreLink" })}>
                        <FormattedMessage id="learnMore" />
                    </a>
                </div>
                <footer
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        paddingBottom: 80,
                        flexWrap: "wrap",
                        backgroundColor: "black"
                    }}
                >
                    <a href={formatMessage({ id: "cecHomePageLink" })}>
                        <div style={{ minWidth: 115 }}>
                            <Image
                                style={{ height: 115, width: 115, marginBottom: 30, maxWidth: "none" }}
                                src={logo}
                                alt="Butterflies over the earth, a brand logo incoporating CEC, CCA and CCE"
                            />
                        </div>
                    </a>
                    <section id="contact" />
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            color: "white",
                            paddingLeft: 10
                        }}
                    >
                        <h4 style={{ marginBottom: 10 }}>
                            <FormattedMessage id="contactUs" />
                        </h4>
                        <FormattedMessage id="contactUsDescription" />
                        <span>
                            <FormattedMessage id="email" />:{" "}
                            <a className="contactUsLink" href={`mailto:${formatMessage({ id: "contactUsEmail" })}`}>
                                <FormattedMessage id="contactUsEmail" />
                            </a>
                        </span>

                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignSelf: "center",
                                marginTop: 30,
                                marginLeft: -20
                            }}
                        >
                            <Button
                                style={{ marginLeft: 10, height: 36 }}
                                circular
                                color="facebook"
                                icon="facebook"
                                as="a"
                                href={formatMessage({ id: "facebookLink" })}
                            />
                            <Button
                                style={{ marginLeft: 10, height: 36 }}
                                circular
                                color="twitter"
                                icon="twitter"
                                as="a"
                                href={formatMessage({ id: "twitterLink" })}
                            />
                            <Button
                                style={{ marginLeft: 10, height: 36 }}
                                circular
                                color="youtube"
                                icon="youtube"
                                as="a"
                                href={formatMessage({ id: "youtubeLink" })}
                            />
                            <Button
                                style={{ marginLeft: 10, height: 36 }}
                                circular
                                color="linkedin"
                                icon="linkedin"
                                as="a"
                                href={formatMessage({ id: "linkedinLink" })}
                            />
                            <div className="copy-version">
                                <div className="copyright">
                                    <span>© {new Date().getFullYear()}</span>
                                </div>
                                <Version />
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </>
    );
}
