import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { Icon } from "semantic-ui-react";
import { getCBTLink } from "../../utilities/Utility";
import orangeDiamond from "../../images/iconwin.png";
import { CultureContext } from "../../context/CultureContext";

export default function CBTLink() {
    const culture = useContext(CultureContext);
    const paragraphClassName = culture === "en-US" ? "cbtLinkParagraphEn" : "cbtLinkParagraphEsFr";
    return (
        <div className="ui fluid image orangeIconLink cbtLink">
            <a href={getCBTLink(culture)}>
                <img src={orangeDiamond} alt="Link to Cross-border transfers" style={{ width: 116, height: 116 }} />
                <div
                    className="iconButton"
                    style={{
                        position: "absolute",
                        bottom: 50,
                        marginLeft: 40,
                        width: "auto",
                        height: "auto"
                    }}
                >
                    <Icon name="exchange" size="big" style={{ paddingTop: 10 }} inverted />
                </div>
                <p className={paragraphClassName}>
                    <FormattedMessage id="cbtLink" />
                </p>
            </a>
        </div>
    );
}
