import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import "../components/layout.css";
import Share from "../components/Share";
import prtrImage from "../images/PRTR-v5.png";
import prtrImageES from "../images/PRTR-SP.png";
import prtrImageFR from "../images/PRTR-FR.png";
import PageWrapper from "./PageWrapper";

interface GlossaryProps {
    lang: string;
    href: string;
}

export default function Glossary({ lang, href }: GlossaryProps) {
    const title = useIntl().formatMessage({ id: "glossarytitle" });
    return (
        <>
            <PageWrapper
                title={title}
                keywords={["terminology", "North American PRTRs", "taking stock"]}
                lang={lang}
                originalPath="terminology"
            >
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <div
                        style={{
                            maxWidth: 900,
                            margin: 50,
                            paddingTop: 50,
                            paddingBottom: 50
                        }}
                    >
                        <h1 style={{ fontSize: 36 }}>
                            <FormattedMessage id="glossarytitle" />
                        </h1>
                        <p style={{ fontSize: 18 }}>
                            <FormattedMessage
                                id="glossaryp1"
                                values={{
                                    style: (
                                        <em>
                                            <FormattedMessage id="liststyle1" />
                                        </em>
                                    )
                                }}
                            />
                        </p>
                        <div style={{ textAlign: "center", margin: 32 }}>
                            {lang === "en" ? (
                                <img
                                    src={prtrImage}
                                    style={{ width: "100%" }}
                                    alt="a chart outlining on site and off site releases"
                                />
                            ) : lang === "fr" ? (
                                <img
                                    src={prtrImageFR}
                                    style={{ width: "100%" }}
                                    alt="a chart outlining on site and off site releases"
                                />
                            ) : (
                                <img
                                    src={prtrImageES}
                                    style={{ width: "100%" }}
                                    alt="a chart outlining on site and off site releases"
                                />
                            )}
                        </div>
                        <p style={{ fontSize: 18 }}>
                            <FormattedMessage
                                id="glossarylist1"
                                values={{
                                    style: (
                                        <strong>
                                            <em>
                                                <FormattedMessage id="boldlist1" />
                                            </em>
                                        </strong>
                                    )
                                }}
                            />
                        </p>
                        <ul>
                            <li style={{ fontSize: 18 }}>
                                <FormattedMessage id="glossarybullet1" />
                            </li>
                            <li style={{ fontSize: 18 }}>
                                <FormattedMessage id="glossarybullet2" />
                            </li>
                            <li style={{ fontSize: 18 }}>
                                <FormattedMessage id="glossarybullet3" />
                            </li>
                            <li style={{ fontSize: 18 }}>
                                <FormattedMessage id="glossarybullet4" />
                            </li>
                        </ul>
                        <p style={{ fontSize: 18 }}>
                            <FormattedMessage
                                id="glossaryp2"
                                values={{
                                    style: (
                                        <strong>
                                            <em>
                                                <FormattedMessage id="boldp2" />
                                            </em>
                                        </strong>
                                    )
                                }}
                            />
                        </p>
                        <p style={{ fontSize: 18 }}>
                            <FormattedMessage
                                id="glossaryp3"
                                values={{
                                    style: (
                                        <strong>
                                            <em>
                                                <FormattedMessage id="transfersRecycling" />
                                            </em>
                                        </strong>
                                    )
                                }}
                            />
                        </p>
                        <p style={{ fontSize: 18 }}>
                            <FormattedMessage
                                id="glossaryp4"
                                values={{
                                    style: (
                                        <strong>
                                            <em>
                                                <FormattedMessage id="boldp4" />
                                            </em>
                                        </strong>
                                    )
                                }}
                            />
                        </p>
                        <p style={{ fontSize: 18 }}>
                            <FormattedMessage
                                id="glossaryp5"
                                values={{
                                    style: (
                                        <em>
                                            <FormattedMessage id="stylep5" />
                                        </em>
                                    )
                                }}
                            />
                        </p>
                        <p style={{ fontSize: 18 }}>
                            <FormattedMessage
                                id="glossaryp6"
                                values={{
                                    style: (
                                        <strong>
                                            <em>
                                                <FormattedMessage id="stylep6" />
                                            </em>
                                        </strong>
                                    )
                                }}
                            />
                        </p>
                        <div style={{ margin: 10 }}>
                            <p style={{ fontSize: 16 }}>
                                <FormattedMessage id="share" />:
                            </p>
                            <Share href={href} />
                        </div>
                    </div>
                </div>
            </PageWrapper>
        </>
    );
}
