import React, { createContext, useCallback, useMemo, useState } from "react";
import { useIntl } from "react-intl";
// eslint-disable-next-line import/no-restricted-paths
import { DropDownOption, DropdownProps } from "../components/Chart";
import { years } from "../config";

interface ChartDropDownOptions {
    dropdowns: {
        chartTypeDropdown: DropdownProps;
        yearDropdown: DropdownProps;
        agencyDropdown: DropdownProps;
        chemicalTypeDropdown: DropdownProps;
    };
    values: {
        chartOption: DropDownOption;
        year: DropDownOption;
        agencyID: DropDownOption;
        chemicalTypeID: DropDownOption;
    };
}

export const ChartContext = createContext<ChartDropDownOptions>({
    dropdowns: {
        chartTypeDropdown: {
            options: [{ text: "", value: "" }],
            setOption: () => {},
            value: "",
            disabled: false
        },
        yearDropdown: {
            options: [{ text: "", value: "" }],
            setOption: () => {},
            value: "",
            disabled: false
        },
        agencyDropdown: {
            options: [{ text: "", value: "" }],
            setOption: () => {},
            value: "",
            disabled: false
        },
        chemicalTypeDropdown: {
            options: [{ text: "", value: "" }],
            setOption: () => {},
            value: "",
            disabled: false
        }
    },
    values: {
        chartOption: { text: "", value: "" },
        year: { text: "", value: "" },
        agencyID: { text: "", value: "" },
        chemicalTypeID: { text: "", value: "" }
    }
});

interface ChartProviderProps {
    children: JSX.Element | null;
}

export const ChartProvider = ({ children }: ChartProviderProps) => {
    const { formatMessage } = useIntl();
    const tpnaTitleID = "tpnaTitle";
    const industryTitleID = "industryTitle";

    const tpnaOptionText = formatMessage({ id: tpnaTitleID });
    const tinaOptionText = formatMessage({ id: industryTitleID });

    const chartOptions = useMemo(() => {
        return [
            { text: tinaOptionText, value: industryTitleID },
            { text: tpnaOptionText, value: tpnaTitleID }
        ];
    }, [tpnaOptionText, tinaOptionText]);

    const [chartOption, setChartOption] = useState<DropDownOption>(chartOptions[1]);

    const agencyDropDownOptions = useMemo(
        () => [
            { text: formatMessage({ id: "northAmerica" }), value: "northAmerica", key: "" },
            { text: formatMessage({ id: "canada" }), value: "canada", key: "1" },
            { text: formatMessage({ id: "mexico" }), value: "mexico", key: "2" },
            { text: formatMessage({ id: "unitedStates" }), value: "unitedStates", key: "3" }
        ],
        [formatMessage]
    );

    const chemicalTypeDropDownOptions = useMemo(
        () => [
            { text: formatMessage({ id: "allPollutantTypes" }), value: "allPollutantTypes", key: "" },
            { text: formatMessage({ id: "devReproToxins" }), value: "devReproToxins", key: "3" },
            { text: formatMessage({ id: "knownOrSusCarc" }), value: "knownOrSusCarc", key: "2" },
            { text: formatMessage({ id: "metals" }), value: "metals", key: "1" },
            { text: formatMessage({ id: "persBioToxic" }), value: "persBioToxic", key: "7" }
        ],
        [formatMessage]
    );

    const yearDropDownOptions = useMemo(
        () =>
            years.map((year) => {
                return {
                    text: year !== "allYears" ? year.toString() : formatMessage({ id: year }),
                    value: year !== "allYears" ? year.toString() : "allYears"
                };
            }),
        [formatMessage]
    );
    const [year, setYear] = useState<{ text: string; value: string }>(yearDropDownOptions[1]);
    const [agencyID, setAgencyID] = useState<DropDownOption>(agencyDropDownOptions[0]);
    const [chemicalTypeID, setChemicalTypeID] = useState<DropDownOption>(chemicalTypeDropDownOptions[0]);

    const updateYear = useCallback(
        (val: string) => {
            const newYear = yearDropDownOptions.find((option) => option.value === val) ?? yearDropDownOptions[1];
            setYear(newYear);
        },
        [yearDropDownOptions]
    );

    const updateAgencyID = useCallback(
        (val: string) => {
            const newAgencyID =
                agencyDropDownOptions.find((option) => option.value === val) ?? agencyDropDownOptions[0];
            setAgencyID(newAgencyID);
        },
        [agencyDropDownOptions]
    );

    const updateChemicalTypeID = useCallback(
        (val: string) => {
            const newChemicalID =
                chemicalTypeDropDownOptions.find((option) => option.value === val) ?? chemicalTypeDropDownOptions[0];
            setChemicalTypeID(newChemicalID);
        },
        [chemicalTypeDropDownOptions]
    );

    const updateChartOption = useCallback(
        (val: string) => {
            const newChart = chartOptions.find((option) => option.value === val) ?? chartOptions[0];
            setChartOption(newChart);
        },
        [chartOptions]
    );

    const chartTypeDropdown: DropdownProps = {
        options: chartOptions,
        value: chartOption.value,
        setOption: updateChartOption,
        disabled: false
    };

    const yearDropdown: DropdownProps = {
        options: yearDropDownOptions,
        value: year.value,
        setOption: updateYear,
        disabled: false
    };
    const agencyDropdown: DropdownProps = {
        options: agencyDropDownOptions,
        value: agencyID.value,
        setOption: updateAgencyID,
        disabled: false
    };
    const chemicalTypeDropdown: DropdownProps = {
        options: chemicalTypeDropDownOptions,
        value: chemicalTypeID.value,
        setOption: updateChemicalTypeID,
        disabled: false,
        minWidth: "15.5em"
    };

    const chartDropDowns = {
        chartTypeDropdown,
        yearDropdown,
        agencyDropdown,
        chemicalTypeDropdown
    };

    const chartDropDownValues = {
        chartOption,
        year,
        agencyID,
        chemicalTypeID
    };

    return (
        <ChartContext.Provider value={{ dropdowns: chartDropDowns, values: chartDropDownValues }}>
            {children}
        </ChartContext.Provider>
    );
};
