import { years } from "../config";
import "./ChartComponents/BarChart.css";

export type ChartName = "TPNA" | "Industry";

export const strokes = [
    "#8884d8",
    "#974163",
    "#000000",
    "#f47520",
    "#1d44d8",
    "#95B0B5",
    "#697d00",
    "#0067b3",
    "#b37400",
    "#3406b3",
    "#2F4538"
];

export interface ResponseData {
    Value: number;
    Key: { Name: string; ID: number };
    Year: number;
}

export interface Response {
    data: ResponseData[] | string;
}

export interface ChartData {
    [index: string]: string | number;
    name: string;
    total: number;
    year: number;
    id: number;
}

export interface DropDownOption {
    key?: string;
    value: string;
    text: string;
}

export interface MediaTypeDropDownOption extends DropDownOption {
    mediaTypeValue: string;
    sortProperty: string;
}

export interface DropdownProps {
    options: { text: string; value: string }[];
    setOption: (val: string) => void;
    value: string;
    disabled: boolean;
    minWidth?: string | number;
}

export interface LineGraphDotPayload {
    [key: string]: number;
    year: number;
}

export type LineChartData = { year: number; [key: string]: number }[];

export type Results = { [key: number]: ChartData[] };

export type IDHashTable = { [key: string]: number };

export interface BarChartDataElement {
    name: string;
    total: number;
    releasesTotal?: number;
    id?: number;
    topIndustry?: string[];
    topPollutants?: string[];
    topValues?: string[];
}
export type BarChartData = BarChartDataElement[];

export const getBarColor = (name: string, formatMessage: (val: { id: string }) => string) => {
    const releaseIds = ["releasesAir", "releasesWater", "undergroundInjection", "releasesLand"];
    if (releaseIds.map((id) => formatMessage({ id })).includes(name)) {
        return "#F37831";
    }
    return "#68d391";
};

export const IDReducer = (results: IDHashTable, item: ChartData) => {
    results[item.name] = item.id;
    return results;
};

export const sumBarChartValues = (data: BarChartData) => {
    return Math.floor(data.reduce((prevItem, currItem) => (currItem.total ?? 0) + prevItem, 0));
};

export const sumLineChartValues = (data: LineChartData) => {
    const sumVals = (obj: { [key: string]: number }) => {
        return Object.values(obj).reduce((prevItem, currItem) => (currItem ?? 0) + prevItem, 0);
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const result = data.map((d) => (({ year, ...o }) => o)(d));
    const sumResult = result.reduce((prevItem, currItem) => sumVals(currItem) + prevItem, 0);
    return Math.floor(sumResult);
};

export const yearDescendingOptions = years.map((year) => ({ text: year.toString(), value: year.toString() }));
