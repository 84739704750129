import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./layout.css";

interface LayoutProps {
    children: JSX.Element;
}

export default function Layout({ children }: LayoutProps) {
    const location = useLocation();
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        setInitialized(true);
    }, []);

    useEffect(() => {
        if (initialized) {
            document.documentElement.scrollTo({ top: 0, left: 0, behavior: "auto" });
        }
    }, [initialized, location]);

    return <>{children}</>;
}
